/**
 * Download a file on client.
 * @param filename file name of download.
 * @param text string to download.
 */
export const download = (filename: string, text: string) => {
    // Create a tag w/ encoded text.
    const a = document.createElement('a');
    a.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    a.setAttribute('download', filename);
  
    // Hide a tag
    a.style.display = 'none';

    // Append a tag
    document.body.appendChild(a);
  
    // Click, triggering download.
    a.click();
  
    // Remove a tag.
    document.body.removeChild(a);
};
