import React, { useState, Fragment, useCallback } from 'react';
import { Channel as StreamChannel, UserResponse } from 'stream-chat';
import Filter from 'bad-words';
import { MemberListItem } from './memberListItem';
import { ChatContent } from './chatContent';
import { chatClient } from './util/chatClient';
import { useStreamChannel } from '../../shared/hooks/useStreamChannel';
import { endChat } from '../../../services/channelService';

const filter = new Filter();
filter.addWords(...['gay']);

const CHAT = 'CHAT';
const PARTICIPANTS = 'PARTICIPANTS';

export const ModerationPanel: React.FC = () => {
  const { channelId, channel, disabled } = useStreamChannel();
  const [activeTab, setActiveTab] = useState(CHAT);
  const [scrollLock, setScrollLock] = useState(false);
  const [pauseChat, setPauseChat] = useState(channel.data.frozen);
  const [members, setMembers] = useState([]);

  // Recursive helper method to query channel participants
  const queryParticipants = async (
    channelId: string,

    participants: UserResponse[] = [],
  ) => {
    const additionalParams = participants.length && {
      offset: participants.length,
    };

    const { users: newParticipants } = await chatClient.queryUsers(
      { channelId: channelId, role: 'user' },
      { name: 1 },
      {
        limit: 100,
        presence: false,
        watch: false,
        offset: participants.length,
      },
    );

    let allParticipants = [...participants, ...newParticipants];

    // De-dupe participant list and remove any participants that are not currently online
    allParticipants = allParticipants.filter(
      (participant, index, self) =>
        participant.online &&
        index === self.findIndex((i) => i.name === participant.name),
    );

    return newParticipants.length !== 100
      ? allParticipants
      : queryParticipants(channelId, allParticipants);
  };

  // Get list of all channel members
  const getMembers = useCallback(async () => {
    if (channelId) {
      const memberList = await queryParticipants(channelId);
      setMembers(memberList);
    }
  }, [channelId]);

  const handlePauseChat = useCallback(async () => {
    const frozen = !pauseChat;
    setPauseChat(frozen);
    const text = frozen
      ? 'Chat has been paused by the moderators.'
      : 'Chat has been resumed by the moderators.';

    await channel.update({ frozen }, { text });
  }, [pauseChat, setPauseChat, channel]);

  const handleEndChat = useCallback(async () => {
    channelId && (await endChat(channelId));
  }, [channel]);

  return (
    <Fragment>
      {!disabled && (
        <Fragment>
          <div className="chat__option-tabs">
            <button
              className="btn-barnes"
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(CHAT);
              }}
            >
              Chat
            </button>
            <button
              className="btn-barnes"
              onClick={(e) => {
                e.preventDefault();
                getMembers();
                setActiveTab(PARTICIPANTS);
              }}
            >
              Participants
            </button>
          </div>
          <div className="chat__option-tabs option-tabs--secondary">
            <button
              className="btn-barnes btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                setScrollLock(!scrollLock);
              }}
            >
              {scrollLock ? 'Unlock Scroll' : 'Lock Scroll'}
            </button>
            <button
              className="btn-barnes btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                handlePauseChat();
              }}
            >
              {pauseChat ? 'Resume' : 'Pause'}
            </button>
            <button
              className="btn-barnes btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                handleEndChat();
              }}
            >
              End
            </button>
          </div>
        </Fragment>
      )}
      {activeTab === CHAT && (
        <ChatContent isAdmin={true} scrollLock={scrollLock} />
      )}

      {activeTab === PARTICIPANTS && (
        <div className="chat__participant-list">
          {members.map((member, i) => (
            <MemberListItem
              key={i}
              member={member}
              channelId={channelId}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
};
