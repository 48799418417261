import React, { useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { AuthContext, useLogin } from './contexts/authContext';
import { AdminContext } from './contexts/adminContext';
import {
  DarkThemeContext,
  useDarkTheme,
} from './contexts/darkThemeContext';
import { ChannelContext } from './contexts/channelContext';
import { UserContext } from './contexts/userContext';
import { Stream } from './components/app/stream/stream';
import { PollResults } from './components/app/pollResults/pollResults';
import { Admin } from './components/admin/admin';
import { Nav } from './components/shared/nav';
import { SelectStream } from './components/app/selectStream';
import {
  TEACHER_ROUTE_DELIMITER,
  NAMED_ROUTE_DELIMITER,
  MINOR,
} from './constants';

export default () => {
  // For authentication in admin panel
  const {
    login: [isLoggedIn, setIsLoggedIn],
    authError: [isAuthError, setIsAuthError],
  } = useLogin();

  // Determine if a logged in user is acting as admin in chat.
  const [isAdmin, setIsAdmin] = useState(isLoggedIn);

  // For theme context information
  const [isDarkTheme, setIsDarkTheme] = useDarkTheme();

  // Channel context information
  const [slowMode, setSlowMode] = useState(false);
  const [cooldown, setCooldown] = useState(10);
  const [simpleChat, setSimpleChat] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(''); // default as empty string
  const [demographic, setDemographic] = useState(MINOR);

  // User context information
  const userId = localStorage.getItem('userId');
  const setUserId = (userId: string) =>
    localStorage.setItem('userId', userId);
  const token = localStorage.getItem('token');
  const setToken = (token: string) =>
    localStorage.setItem('token', token);
  const manualUserName = localStorage.getItem('manualUserName');
  const setManualUserName = (userName: string) =>
    localStorage.setItem('manualUserName', userName);
  const channelId = localStorage.getItem('channelId');
  const setChannelId = (channelId: string) =>
    localStorage.setItem('channelId', channelId);
  const [joinedChat, setJoinedChat] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isAuthError,
        setIsLoggedIn,
        setIsAuthError,
      }}
    >
      <AdminContext.Provider
        value={{
          isAdmin,
          setIsAdmin,
        }}
      >
        <DarkThemeContext.Provider
          value={{
            isDarkTheme,
            setIsDarkTheme,
          }}
        >
          <BrowserRouter>
            {/** Nav bar */}
            <Nav />

            <Switch>
              {/** Splash */}
              <Route exact path="/">
                <SelectStream />
              </Route>

              {/** Admin */}
              <Route path="/admin">
                <Admin />
              </Route>

              {/** Poll */}
              <Route path="/:slug/poll">
                <ChannelContext.Provider
                  value={{
                    slowMode,
                    setSlowMode,
                    cooldown,
                    setCooldown,
                    simpleChat,
                    setSimpleChat,
                    welcomeMessage,
                    setWelcomeMessage,
                    demographic,
                    setDemographic,
                  }}
                >
                  <PollResults />
                </ChannelContext.Provider>
              </Route>

              {/** Specific stream */}
              <Route
                path={[
                  `/${TEACHER_ROUTE_DELIMITER}/:slug`,
                  `/${NAMED_ROUTE_DELIMITER}/:slug`,
                  '/:slug',
                ]}
              >
                <ChannelContext.Provider
                  value={{
                    slowMode,
                    setSlowMode,
                    cooldown,
                    setCooldown,
                    simpleChat,
                    setSimpleChat,
                    welcomeMessage,
                    setWelcomeMessage,
                    demographic,
                    setDemographic,
                  }}
                >
                  <UserContext.Provider
                    value={{
                      userId,
                      setUserId,
                      token,
                      setToken,
                      manualUserName,
                      setManualUserName,
                      channelId,
                      setChannelId,
                      joinedChat,
                      setJoinedChat,
                    }}
                  >
                    <Stream />
                  </UserContext.Provider>
                </ChannelContext.Provider>
              </Route>

              {/** Catch any non-existing routes and redirect to root. */}
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </BrowserRouter>
        </DarkThemeContext.Provider>
      </AdminContext.Provider>
    </AuthContext.Provider>
  );
};
