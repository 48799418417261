import { createContext } from "react";
import { MINOR } from "../constants";

export type ChannelContextType = {
  slowMode: boolean;
  setSlowMode?: React.Dispatch<React.SetStateAction<boolean>>;
  cooldown: number;
  setCooldown?: React.Dispatch<React.SetStateAction<number>>;
  simpleChat: boolean;
  setSimpleChat?: React.Dispatch<React.SetStateAction<boolean>>;
  welcomeMessage: string;
  setWelcomeMessage?: React.Dispatch<React.SetStateAction<string>>;
  demographic: string;
  setDemographic?: React.Dispatch<React.SetStateAction<string>>;
};

export const ChannelContext = createContext<ChannelContextType>({
  slowMode: false,
  setSlowMode: null,
  cooldown: 10,
  setCooldown: null,
  simpleChat: false,
  setSimpleChat: null,
  welcomeMessage: "",
  setWelcomeMessage: null,
  demographic: MINOR,
  setDemographic: null,
});
