import { ChannelMap } from '../../admin/console/console';
import { Channel } from '../../../../shared/channelPayloads';
import { CURRENT_DATE, PAST, PRESENT, FUTURE, TEST } from "../../../constants";

export type BroadcastingState = typeof PAST | typeof PRESENT | typeof FUTURE | typeof TEST;
/**
 * Calculate if this channel is currently broadcasting.
 * @param {Channel} channel individual channel from slug.
 * @returns {BroadcastingState} the state of the channel
 */
export const isCurrentlyBroadcasting = (channel: Channel): BroadcastingState => {
    const startDate = new Date(channel.availability_start);
    const endDate = new Date(channel.availability_end);

    // If slug is "test", return as test channel
    if (channel.slug === "test") {
        return TEST;
    }

    // If we do not have either value, return as present.
    if (!channel.availability_start || !channel.availability_end) {
        return PRESENT;
    }

    // If this is in the past
    if (CURRENT_DATE > endDate) {
        return PAST;

        // If this is in the future
    } else if (CURRENT_DATE < startDate) {
        return FUTURE;
    }

    return PRESENT;
};

/**
 * Convert channel into timed sections.
 * @param {Channel[]} channels channels from server.
 * @returns {ChannelMap}
 */
export const convertToChannelMap = (channels: Channel[]): ChannelMap => (
    channels.reduce((acc, next) => {
        const key = isCurrentlyBroadcasting(next);
        const sortedChannels =
            [...(acc[key] as Channel[]), next]
                .sort(
                    (a, b) => (
                        // Sort Past channels descending and all other channels ascending by start
                        key !== PAST ?
                            new Date(a.availability_start).valueOf() - new Date(b.availability_start).valueOf()
                            :
                            new Date(b.availability_start).valueOf() - new Date(a.availability_start).valueOf()
                    )
                )
        return { ...acc, [key]: sortedChannels };
    }, { past: [], present: [], future: [], test: [] } as ChannelMap)
);
