import { useCallback } from 'react';

/**
 * Wrap async HTTP requests that that modify an error message.
 * @param {() => void} fn - function to be wrapped in method that modifies errorState.
 * @param {React.Dispatch<React.SetStateAction<string>>} setErrorState - error state hook/
 * @returns {() => void} wrapped function that updates state.
 */
export const useErrorMessageWrapper = (
    fn: () => Promise<any>,
    setErrorState: React.Dispatch<React.SetStateAction<string>>
) => (
    useCallback(async () => {
        try {
            await fn();
        } catch (e) {
            // For any errors, log.
            console.error(e);

            // If this is a string, set it to our form error.
            if (typeof e === 'string') {
                setErrorState(e);
            }
        }
    }, [fn, setErrorState])
);

