import React from 'react';
import { useLocation } from 'react-router-dom';
import { BarnesLogo } from '../../assets/icons';
import { Toggle } from './toggle';
import { ThemeWrapper } from './themeWrapper';

/**
 * Determine if we are at a poll results route.
 * @param {string} pathname - current pathname.
 * @returns {boolean} - if we are at a poll results route, true.
 */
const isPollResultsRoute = (pathname: string) => {
    const routes = pathname
        .split("/")
        .filter(Boolean);

    return (
        routes.length === 2 &&
        routes[1] === 'poll'
    );
};

export const Nav = () => {
    // Get current pathname and determine if we are at a poll results route.
    // We will not render anything if so.
    const { pathname } = useLocation();
    const isPollResults = isPollResultsRoute(pathname);

    return (
        isPollResults
            ? <></>
            : (<ThemeWrapper
                className="nav"
                elementType="nav"
                render={({ isDarkTheme, setIsDarkTheme }) => (
                    <>
                        <BarnesLogo className="nav__logo" />

                        <div className="nav__theme-toggle">
                            ☼
                            <Toggle
                                className="nav__toggle"
                                onClick={() => setIsDarkTheme(!isDarkTheme)}
                                isOn={!isDarkTheme}
                            />
                            ☾
                        </div>
                    </>
                )}
            />)
    );
};