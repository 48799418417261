import React, { useContext, ReactHTML } from 'react';
import { DarkThemeContext, DarkThemeContextType } from '../../contexts/darkThemeContext';

type ThemeWrapperProps = {
    className: string;
    elementType: keyof ReactHTML;
    render?: (context: DarkThemeContextType) => React.ReactElement; 
};

/**
 * HOC to provide theme to children via appending --dark modifier to supplied className.
 */
export const ThemeWrapper: React.FC<ThemeWrapperProps> = ({
    className,
    elementType,
    render,
    children,
}) => {
    const { isDarkTheme, setIsDarkTheme } = useContext(DarkThemeContext);

    // Apply additional BEM modifier if dark theme global context is set.
    let themedComponentClassName = className;
    if (isDarkTheme) {
        themedComponentClassName = `${themedComponentClassName} ${themedComponentClassName}--dark`;
    }

    return (
        // Rendering element w/o JSX.
        // This is so we can dynamically create the root child element and append any classNames from theme.
        React.createElement(
            elementType,
            {
                className: themedComponentClassName,
            },
            // Render children, if there is no children fall back to render prop.
            // This is to keep context information encapsulated in our ThemeWrapper.
            children ||
            (
                render && render({ isDarkTheme, setIsDarkTheme })
            )
        )
    );
};
