import React, { ReactHTML } from 'react';
import { FormItem, FormItemProps } from './formItem'; 

type StandardInputProps = FormItemProps & {
    elementType: keyof ReactHTML;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    
    // # TODO => type this to be HTMLAttributes
    [key: string]: any;
};
/**
 * Utility component that is FormItem wrapped around plain text input.
 */
export const StandardInput: React.FC<StandardInputProps> = (
    {
        label,
        elementType,
        value,
        onChange,
        ...rest
    }
) => (
    <FormItem label={label}>
        {
            React.createElement(
                elementType,
                {
                    className: elementType === "input"
                        ? "create-channel__form-input"
                        : "create-channel__form-input create-channel__form-input--text-area",
                    type: "text",
                    value,
                    onChange,

                    // Spread rest
                    ...rest,
                },
            )
        }
    </FormItem>
);
