import { ChannelInputForm } from '../channelManager';
import { ChannelInput, Channel } from '../../../../../shared/channelPayloads';

/**
 * Convert timestamp to hours and minutes.
 * @param {string} time - timestamp like 23:34
 * @returns {[number, number]} hours and minutes, respectively
 */
const getHoursAndMinutesFromTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    return [parseInt(hours), parseInt(minutes)];
}

/**
 * Prepare ChannelInputForm and convert to ChannelInput, then pass to callback and execute.
 * @param {ChannelInputForm} channelInputForm form to be converted into @see ChannelInput
 * @param {(channel: ChannelInput) => Promise<Channel>} callback callback to be passed.
 */
export const createOrModifyChannel = (channelInputForm: ChannelInputForm, callback: (channelInput: ChannelInput) => Promise<Channel>) => {
    try {
        const {
            // Dates for transformation
            date,
            availability_start,
            availability_end,    
            ...rest
        } = channelInputForm;
    
        // Convert dates
        const [dateAvailabilityStart, dateAvailabilityEnd] = [availability_start, availability_end]
            .map((availabilityTime) => {
                // Strip out hours and minutes
                const [hours, minutes] = getHoursAndMinutesFromTime(availabilityTime);
    
                // Cast new date object
                const availabilityDate = new Date(date);
    
                // Update times
                availabilityDate.setHours(hours);
                availabilityDate.setMinutes(minutes);
                 
                // Cast to ISO string.
                return availabilityDate.toISOString();
            });
    
        // Convert id
    
        // Pass back Promise from callback.
        return callback({
            availability_start: dateAvailabilityStart,
            availability_end: dateAvailabilityEnd,
    
            ...rest,
        });
    } catch (e) {
        throw (e);
    }
};
