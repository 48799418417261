import React, { useState } from 'react';
import { ThemeWrapper } from '../shared/themeWrapper';
import { Channel } from '../../../shared/channelPayloads';
import { formatTimeRange } from '../../util/formatting';

type DescriptionProps = {
    channel: Channel;
    className?: string
};
export const Description: React.FC<DescriptionProps> = ({
    channel,
    className
}) => (
    <ThemeWrapper
        className={
            className
                ? `description ${className} description`
                : "description"
        }
        elementType="div"
    >
        <div className="container">
            {/** Header */}
            <div className="description__header-section">
                <div>
                    <h1 className="description__header">{channel.title}</h1>
                    <h2 className="description__sub-header">{channel.subtitle}</h2>
                    <h3 className="description__detail">{formatTimeRange(channel.availability_start, channel.availability_end)}</h3>
                </div>
            </div>
                        
            {/** Stream see more */}
            <SeeMore description={channel.description} />
        </div>
    </ThemeWrapper>
);

type SeeMoreProps = { description: string };
/**
 * "See more" toggle component for longer descriptions.
 */
const SeeMore: React.FC<SeeMoreProps> = ({ description }) => {
    const [isShowingMore, setIsShowingMore] = useState(true);

    // Show copy lick or entire thing.
    let copyExtraClassName = 'description__copy';
    if (!isShowingMore) {
        copyExtraClassName = `${copyExtraClassName} description__copy--hidden`;
    }

    return (
        <div className="description__section">
            {/** Copy lick. */}
            <div className={copyExtraClassName}>
                {description.split('\n').map(paragraph => <p key={paragraph}>{paragraph}</p>)}
            </div>

            <button
                className="description__button"
                onClick={() => setIsShowingMore(isShowingMore => !isShowingMore)}
            >
                Show {!isShowingMore ? "More" : "Less"}
            </button>
        </div>
    )
};
