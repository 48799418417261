import React from 'react';
import { ThemeWrapper } from './themeWrapper';
import { SadFace } from '../../assets/icons';

type WarningProps = { text: string };
export const Warning: React.FC<WarningProps> = ({ text }) => (
    <ThemeWrapper
        elementType="div"
        className="warning"
    >
        <SadFace className="warning__icon" />
        <span className="warning__text">{text}</span>
    </ThemeWrapper>
);