import { executePayloadRequest } from './httpService';

/**
 * Sign in with google id_token.
 * @param {IdToken} - id_token from google sign in onSuccess handler.
 * @returns {{ authenticated: boolean }} - true for authenticated, false for otherwise.
 * @throws - @see httpService
 */
export const signIn: (idToken: string) => Promise<{ authenticated: boolean }> = async (idToken) => (
	executePayloadRequest<{ authenticated: boolean }>({
		url: '/api/authentication/sign-in',
        method: 'POST',
        data: { idToken },
	})
);

/**
 * Sign out from GAPI and server.
 * @returns {{ authenticated: boolean }} - true for authenticated, false for otherwise.
 * @throws - @see httpService
 */
export const signOut: () => Promise<{ authenticated: boolean }> = async () => {
	// Log out of GAPI
	const gapi = (window as any).gapi as any;
	// Check if exists before method invocation.
	if (gapi && gapi.auth2) {
		try {
			gapi.auth2
				.getAuthInstance()
				.disconnect();	
		} catch (e) {
			console.error(e);
		}
	}

	return executePayloadRequest<{ authenticated: boolean }>({
		url: '/api/authentication/sign-out',
        method: 'POST',
	})
};
