import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { AdminContext } from '../../contexts/adminContext';
import { Channel } from '../../../shared/channelPayloads';
import {
    META_TITLE,
    META_DESCRIPTION,
    CANONICAL_ROOT,
    META_IMAGE,
    META_PLACENAME
} from '../../constants';

type SiteHelmetProps = {
    channel: Channel | void;
    isBroadcasting: boolean;
};
export const SiteHelmet: React.FC<SiteHelmetProps> = ({
    channel,
    isBroadcasting
}) => {
    const { isAdmin } = useContext(AdminContext);

    const canonicalUrl = CANONICAL_ROOT + window.location.pathname;
    const description = channel ? channel.description : META_DESCRIPTION;

    let title = channel ? `Barnes Live 📡 — ${channel.title}` : META_TITLE;
    if (isAdmin) {
        title = `⚙ ${title}`
    }

    return (
        <Helmet>
            {/** Title */}
            <meta name="title" content={title} />
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content={title} />

            {/** Description */}
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />

            {/** Location */}
            <meta name="geo.placename" content={META_PLACENAME} />

            {/** Other */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:image" content={META_IMAGE} />
        </Helmet>
    );
};
