import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    PollUpdateInput,
    PollCreationInput
} from '../../../../shared/channelPollPayloads';

/**
 * Use polling questions, checking for a predicate before fetching from server.
 * @param {boolean} predicate - check if this should render or not.
 * @param {() => Promise<{ polls: PollCreationInput[] }>} getQuestions - callback that we use to fetch polls.
 * This is so we can wrap our call in an auth mechanism if need be.
 * @returns {[
    PollUpdateInput | PollCreationInput[]>,
        React.Dispatch<React.SetStateAction<PollUpdateInput | PollCreationInput[]>,
    ]} - similar mechanism to a useState hook.
 */
export const usePollQuestions = (predicate: boolean, getQuestions: () => Promise<PollCreationInput[]>) => {
    const [pollQuestions, setPollQuestions] = useState<(PollUpdateInput | PollCreationInput)[]>([]);
    const hasFetched = useRef(false); // Ref to make sure uE only runs once.

    // Set up polling questions if we are in edit mode.
    useEffect(() => {        
        if (predicate && !hasFetched.current) {
            const fetchQuestions = async () => {
                try {
                    const polls = await getQuestions();
                    setPollQuestions(polls || []);
                } catch (e) {
                    console.error(e);
    
                    setPollQuestions([]);
                }
            };
    
            fetchQuestions();
            hasFetched.current = true; // Update ref, that way this will no longer run.
        }
    }, [predicate, pollQuestions, getQuestions]);

    return [pollQuestions, setPollQuestions] as [
        PollCreationInput[],
        React.Dispatch<React.SetStateAction<PollCreationInput[]>>,
    ];
};
