import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import { Chat, Channel } from '@agallagher777/stream-chat-react';
import { Channel as StreamChannel } from 'stream-chat';
import { useSetUpUser } from '../chat/hooks/useSetupUser';
import { chatClient } from '../chat/util/chatClient';
import { useChannel } from '../stream/hooks/useChannel';

/**
 * Wrap poll in stream channel.
 */
export const AdminPrivilegesChatWrapper: React.FC = ({ children }) => {
    const { channel } = useChannel();
    const channelId = channel ? channel.streamchat_channel_id : undefined;

    const [streamChannel, setStreamChannel] = useState<StreamChannel | void>(null);
    const setUpUser = useSetUpUser();
    const hasMountedRef = useRef(false);

    // Set up channel
	useEffect(() => {
        // On receiving channel id.
		if (channelId && !hasMountedRef.current) {
            hasMountedRef.current = true;

			// Build the channel
			const setUpChat = async () => {
				try {
                    // Use client singleton to set up channel
                    // We can set up with the following constant values
                    // as we know that poll route will not render any chat.
					await setUpUser(true, undefined, channelId);

					// Connect to our channel id.
					const chatChannel: StreamChannel = chatClient.channel(
						'livestream',
						channelId,
						{ name: 'Art Talk' }
					);

					// Update react
					setStreamChannel(chatChannel);
				} catch (e) {
					console.error(e);
				}
			};

			setUpChat();
        }

		return () => {
			chatClient.disconnect();
			setStreamChannel(null);
		};
    }, [channelId, setUpUser]);
    
    return (
        <Chat client={chatClient}>
            {
                streamChannel && (
                    <Channel channel={streamChannel}>
                        {children}
                    </Channel>
                )
            }
        </Chat>
    );
};

