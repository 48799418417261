import { executePayloadRequest } from './httpService';
import { Poll } from '../../shared/channelPollPayloads';

/**
 * Get all polls associated with a channel.
 * @param {number | string} - channel id for which we are pulling polls.
 * @returns {PollCreationInput[]} - All channel polls.
 * @throws - @see httpService
 */
export const listChannelPolls: (channelId: number | string) => Promise<Poll[]> = async (channelId) => (
	executePayloadRequest<Poll[]>({
		url: `/api/channel/${channelId}/poll`,
        method: 'GET',
	})
);

/**
 * Get poll by id.
 * @param {number} - poll id.
 * @returns {Poll}
 * @throws - @see httpService
 */
export const getPoll: (pollId: number) => Promise<Poll> = async (pollId) => (
	executePayloadRequest<Poll>({
		url: `/api/channel/poll/${pollId}`,
        method: 'GET',
	})
);
