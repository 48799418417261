/* eslint-disable no-throw-literal */

// Additional params to keep a consistent option bag approach.
type AdditionalFetchParams = {
    url: string;
    data?: { [key: string]: any };
    isRedirect?: boolean;
};
/**
 * Wrapper around the Fetch browser API.
 * @param {RequestInit & AdditionalFetchParams} init Request object with additional params for convenience.
 * @returns {Promise<T>} Object of generic type from URL endpoint.
 */
export const executePayloadRequest = async <T>(request: RequestInit & AdditionalFetchParams): Promise<T> => {
    try {
        const res: Response = await fetch(request.url, {
            // Default values for request.
            body: request.data ? JSON.stringify(request.data) : undefined,
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            // Overwrite any defaults in option bag.
            ...request,
        });

        if (request.isRedirect) {
            return;
        } else {
            // Catch 500 from server.
            if (res.status === 500) {
                throw { message: '500 response from server.', status: 500 };
            }

            // Type json as potential error object.
            const json = (await res.json()) as { status?: number; message?: string };

            // If status and status is 403, throw new error.
            if (json && json.status && json.status === 403) {
                throw { message: json.message || '403 error in HTTP service.', status: 403 };
            }

            // Cast as <T> and return.
            return json as T;
        }
    } catch (e) {
        throw e;
    }
};
