import React from 'react';
import { ThemeWrapper } from './themeWrapper';

type SplashProps = { subtitle: string };
export const Splash: React.FC<SplashProps> = ({
    children,
    subtitle
}) => (
    <ThemeWrapper
        className="log-in"
        elementType="section"
    >
        <div className="log-in__form">
            <h1 className="log-in__header">Barnes Live</h1>
            <h2 className="log-in__header log-in__header--sub">
                {subtitle}
            </h2>
            
            {children}

        </div>
    </ThemeWrapper>
);
