import { createContext } from 'react';

/**
 * @note This context is separate from authContext as a user
 * may potentially be logged in but NOT ❌ be an admin if they have
 * switched users.
 * @see useUserNameLevel for how current admin level is defined.
 */
type AdminContextType = {
    isAdmin: boolean;
    setIsAdmin?: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AdminContext = createContext<AdminContextType>({
    isAdmin: false,
    setIsAdmin: null,
});
