import { useState, useEffect } from 'react';

const TIMEOUT = 10000;

/**
 * Delay posted for 10s.
 */
export const usePoster = (): boolean => {
    const [isShowingPoster, setIsShowingPoster] = useState(false);

    useEffect(() => {
        const sto = setTimeout(() => {
            setIsShowingPoster(true);
        }, TIMEOUT);

        return () => {
            clearTimeout(sto);
        };
    }, []);

    return isShowingPoster;
};
