import dateformat from 'dateformat';

/**
 * Format a single date, in AC the default format is 'dddd, mmmm dS, yyyy'.
 * @param {Date | string} date - date to be formatted.
 * @param {string} format - override for default format.
 * @returns {string} string format of date for universal formatting in application.
 */
export const formatSingleDate = (date: Date | string, format?: string): string => dateformat(date, format || 'mmmm dS, yyyy');

/**
 * Format two dates for a time range.
 * @param {Date | string} startTime - date to be formatted.
 * @param {Date | string} endTime - date to be formatted.
 * @returns {string} string format of date time range.
 */
export const formatTimeRange = (startTime: Date | string, endTime: Date | string) => `${dateformat(startTime, 'shortTime')} to ${dateformat(endTime, 'shortTime')}`;
