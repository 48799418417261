import config from '../../config';

import active from './Emotes-Active.png';
import bumpy from './Emotes-Bumpy.png';
import curved from './Emotes-Curved.png';
import diagonal from './Emotes-Diagonal.png';
import dot from './Emotes-Dot.png';
import fineLine from './Emotes-Fineline.png';
import horizontal from './Emotes-Horizontal.png';
import impasto from './Emotes-Impasto.png';
import opaque from './Emotes-Opaque.png';
import rough from './Emotes-Rough.png';
import smooth from './Emotes-Smooth.png';
import straight from './Emotes-Straight.png';
import thick from './Emotes-Thick.png';
import thin from './Emotes-Thin.png';
import translucent from './Emotes-Translucent.png';
import vertical from './Emotes-Vertical.png';
import wavy from './Emotes-Wavy.png';
import zigZag from './Emotes-Zigzag.png';
import realisticLandscape from './Emotes-Realistic-Landscape.png'
import abstractLandscape from './Emotes-Abstract-Landscape.png'
import realisticPortrait from './Emotes-Realistic-Portrait.png'
import abstractPortrait from './Emotes-Abstract-Portrait.png'
import asymmetrical from './Emotes-Asymmetrical.png'
import symmetrical from './Emotes-Symmetrical.png'
import balance from './Emotes-Balance.png'
import foreground from './Emotes-Foreground.png'
import midground from './Emotes-Midground.png'
import background from './Emotes-Background.png'



const localLocations = {
    active: active,
    bumpy: bumpy,
    curved: curved,
    diagonal: diagonal,
    dot: dot,
    fineLine: fineLine,
    horizontal: horizontal,
    impasto: impasto,
    opaque: opaque,
    rough: rough,
    smooth: smooth,
    straight: straight,
    thick: thick,
    thin: thin,
    translucent: translucent,
    vertical: vertical,
    wavy: wavy,
    zigZag: zigZag,
    realisticPortrait: realisticPortrait,
    abstractPortrait: abstractPortrait,
    realisticLandscape: realisticLandscape,
    abstractLandscape: abstractLandscape,
    asymmetrical: asymmetrical,
    symmetrical: symmetrical,
    balance: balance,
    foreground: foreground,
    midground: midground,
    background: background,


};

const s3Locations = {
    active: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Active.png',
    bumpy: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Bumpy.png',
    curved: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Curved.png',
    diagonal: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Diagonal.png',
    dot: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Dot.png',
    fineLine: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Fineline.png',
    horizontal: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Horizontal.png',
    impasto: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Impasto.png',
    opaque: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Opaque.png',
    rough: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Rough.png',
    smooth: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Smooth.png',
    straight: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Straight.png',
    thick: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Thick.png',
    thin: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Thin.png',
    translucent: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Translucent.png',
    vertical: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Vertical.png',
    wavy: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Wavy.png',
    zigZag: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Zigzag.png',
    realistic: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Realistic.png',
    abstract: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Abstract.png',
    realisticPortrait: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Realistic-Portrait.png',
    abstractPortrait: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Abstract-Portrait.png',
    realisticLandscape: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Realistic-Landscape.png',
    abstractLandscape: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Abstract-Landscape.png',
    asymmetrical: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Asymmetrical.png',
    symmetrical: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Symmetrical.png',
    balance: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Balance.png',
    foreground: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Foreground.png',
    midground: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Midground.png',
    background: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/emoji/Emotes-Background.png',
}

// Use local files for local development or s3 files for production
export default config.nodeEnv === 'development'
    ? localLocations
    : s3Locations;