import React from 'react';
import { useHistory, Link } from 'react-router-dom';

type BackLinkProps = { backLinkOverride?: string };
export const BackLink: React.FC<BackLinkProps> = ({
    children,
    backLinkOverride,
}) => {
    const history = useHistory();

    return (
        <div>
            {
                backLinkOverride
                    ? <Link
                        className="a-basic-link back-link"
                        to={backLinkOverride}
                    >
                        {children}
                    </Link>
                    : <button
                        className="a-basic-link back-link"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        {children}
                    </button>
            }
        </div>
    );
};
