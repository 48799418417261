import React, {
    // useState,
    // useEffect,
} from 'react';
import {
    // useHistory,
    useLocation
} from 'react-router-dom';
import { Splash } from '../shared/splash';
// import { getChannels } from '../../services/channelService';
// import { convertToChannelMap } from '../shared/util/convertToChannelMap';
// import { Channel } from '../../../shared/channelPayloads';
import { ThemeWrapper } from '../shared/themeWrapper';

export const SelectStream = () => {
    // const history = useHistory();
    const location = useLocation<{ error: string }>();

    // const [channels, setChannels] = useState<Channel[]>([]);
    // const [isLoadingChannelsError, setIsLoadingChannelsError] = useState(false);

    // useEffect(() => {
    //     const fetchChannels = async () => {
    //         try {
    //             const channels = await getChannels();
    //             const channelMap = convertToChannelMap(channels);

    //             setChannels(channelMap.present);
    //         } catch (e) {
    //             console.error(e);

    //             setIsLoadingChannelsError(true);
    //         }
    //     }
        
    //     fetchChannels();
    // }, []);

    return (
        <ThemeWrapper
            elementType="main"
            className="admin"
        >
            <div className="admin__content admin__content--padding-bottom">
                <Splash
                    subtitle="The Live Streaming Platform from the Barnes Foundation"
                >
                    {/* <div className="log-in__flex-wrapper">
                        <select
                            className="log-in__select"
                            onChange={({ target: { value }}) => value && history.push(`/stream/${value}`)}
                        >
                            <option>Select live stream</option>
                            {
                                channels.map((channel) => (
                                        <option
                                            key={channel.id}
                                            value={channel.slug}
                                        >
                                            {channel.title} — {channel.slug}
                                        </option>
                                    )
                                )
                            }
                        </select>
                    </div> */}

                    {/** Stream DNE error */}
                    {(location.state?.error) && (
                            <span className="log-in__error">
                                {location.state.error}
                            </span>
                        )
                    }

                    {/** Fetching channels error */}
                    {/* {(isLoadingChannelsError) && (
                            <span className="log-in__error">
                                Error loading channels, please contact <a href="mailto:support@barnesfoundation.org">support@barnesfoundation.org</a>
                            </span>
                        )
                    } */}
                </Splash>
            </div>
        </ThemeWrapper>
    )
}