import {
    useState,
    useCallback,
    useEffect
} from 'react';
import { ChannelInputForm } from '../channelManager';
import { ChannelInput, Channel } from '../../../../../shared/channelPayloads';
import { validateCreateChannelForm } from '../util/validateCreateChannelForm';
import { CURRENT_DATE, MINOR } from "../../../../constants";

/**
 * Get hours and minutes from date.
 * @param {Date} date - date to be parsed.
 * @returns {string} - hours and minutes from date, respectively.
 */
const getTimeFromDate = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const minutesString = minutes < 10 ? `0${minutes}` : minutes; // Pad leading 0.

    return `${hours}:${minutesString}`;
};

// Default form shape.
export const getInitialFormState: (channel?: Channel) => ChannelInputForm = (channel) => {
    let spread = {};
    if (channel) {
        const {
            // Dont need
            streamchat_channel_id,
            
            // Need to parse
            availability_end,
            availability_start,
            
            // Need to wrap in string
            id,

            ...rest
        } = channel;

        spread = {
          availability_start: getTimeFromDate(new Date(availability_start)),
          availability_end: getTimeFromDate(new Date(availability_end)),
          date: new Date(availability_start),

          id,

          ...rest,
        };
    }

    return {
      id: undefined,
      title: "",
      subtitle: "",
      slug: "",
      description: "",
      ivs_playback_url: "",
      demographic: MINOR,
      slow_mode: false,
      cooldown: undefined,
      simple_chat: false,
      welcome_message: "",

      // Currently enabled defaults to false.
      enabled: false,

      // date is a separate object that we will use and
      // update our form on submission.
      availability_start: "",
      availability_end: "",
      date: CURRENT_DATE,

      ...spread,
    };
};

/**
 * Custom hook to manage form state.
 */
export const useForm = (initialFormState: ChannelInputForm) => {
    const [createChannelForm, setCreateChannelForm] = useState(initialFormState);
    const [formErrorMessage, setFormErrorMessage] = useState('');

    // createChannelForm update mechanisms.
    const updateCreateChannelFormByKey = useCallback(
        (spread: { [key in keyof ChannelInputForm]?: any }) => {
            setCreateChannelForm(form => ({ ...form, ...spread }))
        },
        [],
    );
    const updateCreateChannelInput = useCallback((key: keyof ChannelInput) => {
        return ({ target: { value }}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setCreateChannelForm(form => ({ ...form, [key]: value }))
        };
    }, []);

    // Validate form on update, check if form is complete.
    useEffect(() => {
        setFormErrorMessage(validateCreateChannelForm(createChannelForm))
    }, [createChannelForm]);

    return {
        // form data
        channelForm: [
            createChannelForm,
            setCreateChannelForm
        ] as [ChannelInputForm, React.Dispatch<React.SetStateAction<ChannelInputForm>>],
        
        // form validation
        formErrorMessage,
        setFormErrorMessage,

        // callbacks
        updateCreateChannelFormByKey,
        updateCreateChannelInput,
    };
};
