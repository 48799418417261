import React, { HTMLAttributes } from 'react';

type SpinnerProps = HTMLAttributes<HTMLElement>;
export const Spinner: React.FC<SpinnerProps> = ({ className, ...rest }) => (
    <div
        className={`spinner ${className || ''}`}
        {...rest}
    >
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
    </div>
);

type SpinnerSuspenseProps = {
    isLoaded: boolean,
    className?: string,
};
export const SpinnerSuspense: React.FC<SpinnerSuspenseProps> = ({ children, isLoaded, className }) => {
    return (
        <>
            {
                isLoaded
                    ? children
                    : <Spinner className={className} />
            }
        </>
    )
};
