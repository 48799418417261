import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import jsCookie from 'js-cookie';
import { signOut } from '../services/authenticationService';

/**
 * Wrap HTTP requests that require auth in function that modifies auth context.
 * @param {<T>} fn - function to be wrapped in method that modifies AuthErrorContext.
 * @returns {<T>} function with identical shape to argument. 
 */
export function useAuthRequest<T extends (...args) => ReturnType<T>> (fn: T): T {
    const { setIsAuthError } = useContext(AuthContext);

    // Wrap function in try catch.
    // On 403, we will set error state to true and redirect to splash.
    const httpWrapper = useCallback(async (...args) => {
        try {
            const res = await fn(...args);
            return res;
        } catch (e) {
            console.error(e);

            // Handle 403 errors inside of application by redirecting to splash.
            if (e && e.status && e.status === 403) {
                setIsAuthError(true);
            } else {
                throw e;
            }
        }
    }, [fn, setIsAuthError]);

    return httpWrapper as T;
};

/**
 * Determine default auth state by checking for client cookies.
 * @return {boolean} - default authentication state.
 */
const getCookieState: () => boolean = () => {
	const liveClientCookie = jsCookie.get('liveClientCookie');

	// Check if there is a client cookie.
	if (liveClientCookie) {
		const { authenticated, expires } = JSON.parse(liveClientCookie);
		
		// If cookie is authenticated and the expiration date is past the current moment in unix epoch time.
		return Boolean(liveClientCookie && authenticated && expires > Date.now()) || null;
	} else {
		// If there is no cookie, return null.
		return false;
	}
};

type UseLoginHooks = {
    login: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    authError: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};
/**
 * Custom hook to handle authentication context.
 * @returns {UseLoginHooks} hooks to update auth error and login status.
 */
export const useLogin = (): UseLoginHooks  => {
    const [isAuthError, setIsAuthError] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(getCookieState());

    const history = useHistory();

    // If login state changes,
    // we want to reset auth error status.
    useEffect(() => {
        if (isLoggedIn) {
            setIsAuthError(false);
        }
    }, [isLoggedIn]);

    // If an auth error is triggered,
    // we want to log the user out.
    useEffect(() => {
        if (isAuthError) {
            const logOut = async () => {
                try {
                    await signOut();
                } catch (e) {
                    console.log(e);
                } finally {
                    setIsLoggedIn(false);
                    // history.push('/admin/login');
                }
            };
            
            logOut();
        }
    }, [isAuthError, history]);

    return {
        login: [isLoggedIn, setIsLoggedIn],
        authError: [isAuthError, setIsAuthError],
    };
};

type AuthContextType = {
    isLoggedIn: boolean;
    isAuthError: boolean;
    setIsLoggedIn?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAuthError?: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AuthContext = createContext<AuthContextType>({
    isLoggedIn: false,
    isAuthError: false,
    setIsLoggedIn: null,
    setIsAuthError: null,
});
