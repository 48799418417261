import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useCallback,
} from 'react';
import {
  ChannelHeader,
  Thread,
  Window,
  MessageInput,
} from '@agallagher777/stream-chat-react';
import { Channel as StreamChannel, Message } from 'stream-chat';
import { PickerProps } from 'emoji-mart';
import { MessageList } from './messageList';
import { customEmojis } from './util/customEmojis';
import { useFilter } from './hooks/useFilter';
import {
  NOT_ALLOWED_EMOJIS,
  ADULTS_ONLY_EMOJIS,
  MINOR,
} from '../../../constants';
import { ChannelContext } from '../../../contexts/channelContext';
import { UserContext } from '../../../contexts/userContext';
import { useStreamChannel } from '../../shared/hooks/useStreamChannel';
import { restartChat } from '../../../services/channelService';

const filter = useFilter();

const DEBOUNCE_TIME = 10000;

type ChatContentProps = {
  isAdmin: boolean;
  scrollLock?: boolean;
};
export const ChatContent: React.FC<ChatContentProps> = ({
  isAdmin,
  scrollLock,
}) => {
  const { channel, frozen, disabled, channelId } = useStreamChannel();

  const { slowMode, cooldown, simpleChat, demographic } =
    useContext(ChannelContext);
  const [isDebounce, setIsDebounce] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const { joinedChat } = useContext(UserContext);

  // use cooldown setting if slow mode is enabled and cooldown is greater than default debounce time
  const cooldownTime =
    slowMode && cooldown > 10 ? cooldown * 1000 : DEBOUNCE_TIME;

  // Turn off debounce after interval.
  useEffect(() => {
    let sto: ReturnType<typeof setTimeout>;

    if (isDebounce && !isAdmin) {
      sto = setTimeout(() => {
        setIsDebounce(false);
      }, cooldownTime);
    }

    // Clear timeout on unmount
    return () => {
      if (sto) {
        clearTimeout(sto);
      }
    };
  }, [isDebounce, isAdmin, cooldownTime]);

  // Set whether or not input is visible
  useEffect(() => {
    setShowInput(joinedChat && !frozen);
  }, [joinedChat, frozen]);

  const handleRestartChat = useCallback(async () => {
    channelId && (await restartChat(channelId));
  }, [channelId, restartChat]);

  // Handle debouncing messages
  // Add BEM modifier if message cooldown is in effect.
  let cooldownClassName = 'chat__cooldown';
  if (isDebounce) {
    cooldownClassName = `${cooldownClassName} chat__cooldown--active`;
  }

  let cooldownBarStyles = {};
  if (isDebounce) {
    cooldownBarStyles = {
      transition: `width ${cooldownTime / 1000}s`,
    };
  }

  return (
    <Fragment>
      {disabled ? (
        <div className="warning-wrapper">
          <div>The chat for this event has ended.</div>

          {isAdmin && (
            <button
              className="btn-barnes btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                handleRestartChat();
              }}
            >
              Restart Chat
            </button>
          )}
        </div>
      ) : (
        <Fragment>
          <MessageList
            simpleChat={simpleChat}
            scrollLock={scrollLock}
          />

          {showInput && (
            <Fragment>
              {/** Debouncing cooldown */}
              {!isAdmin && (
                <div className={cooldownClassName}>
                  <div
                    className="chat__cooldown-bar"
                    style={cooldownBarStyles}
                  ></div>
                  <span className="chat__cooldown-text">
                    Cooling down
                  </span>
                </div>
              )}
              <MessageInput
                publishTypingEvent={false}
                noFiles={true}
                emojiPickerProps={
                  {
                    emojiTooltip: true,
                    exclude: ['recent', 'flags'],
                    // include: ['custom', 'people'] as any[],
                    emojisToShowFilter: (emoji: {
                      unified: string;
                    }) =>
                      // Filter out for non-custom emojis
                      // and emojis that aren't on not-allow list.
                      // filter out adults only emojis if demographic is minor
                      demographic === MINOR
                        ? !emoji.unified ||
                          (emoji.unified &&
                            !NOT_ALLOWED_EMOJIS.includes(
                              emoji.unified,
                            ) &&
                            !ADULTS_ONLY_EMOJIS.includes(
                              emoji.unified,
                            ))
                        : !emoji.unified ||
                          (emoji.unified &&
                            !NOT_ALLOWED_EMOJIS.includes(
                              emoji.unified,
                            )),
                    custom: customEmojis,
                    // onSelect: (emoji) => console.log(emoji)
                  } as PickerProps
                }
                overrideSubmitHandler={async (message: Message) => {
                  try {
                    if (!isDebounce || isAdmin) {
                      setIsDebounce(true);
                      await channel.sendMessage({
                        ...message,
                        text: filter.clean(message.text),
                      } as Message);
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            </Fragment>
          )}

          {!showInput && frozen && (
            <div className="chat__input--message">
              Chat has been paused by the moderators.
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
