import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import {
  Chat,
  Channel,
  Window,
  ChannelHeader,
  Thread,
} from '@agallagher777/stream-chat-react';
import { Channel as StreamChannel } from 'stream-chat';
import { UserInfo } from './userInfo';
import { WelcomeMessage } from './welcomeMessage';
import { ThemeWrapper } from '../../shared/themeWrapper';
import { Spinner } from '../../shared/spinner';
import { Warning } from '../../shared/warning';
import { AuthContext } from '../../../contexts/authContext';
import { AdminContext } from '../../../contexts/adminContext';
import { UserContext } from '../../../contexts/userContext';
import { useSetUpUser } from './hooks/useSetupUser';
import { chatClient } from './util/chatClient';
import { GuestUserProfile } from './util/guestUsers';
import { Poll } from '../../../../shared/channelPollPayloads';
import { ChatContent } from './chatContent';
import { ModerationPanel } from './moderationPanel';
import { useTime } from './hooks/useTime';
import { restartChat } from '../../../services/channelService';

type ChatModuleProps = {
  isSubmitted: boolean;
  userName: GuestUserProfile | string | void;
  email: string | void;
  channelId?: string | void;
  modPollQuestion: Poll | void;
};

const ChatModule: React.FC<ChatModuleProps> = ({
  isSubmitted,
  userName,
  email,
  channelId,
  modPollQuestion,
}) => {
  // Get global contexts
  const { isLoggedIn } = useContext(AuthContext);
  const { isAdmin } = useContext(AdminContext);
  const { joinedChat } = useContext(UserContext);

  // Chat state hooks
  const [channel, setChannel] = useState<StreamChannel | void>(null);
  const [isError, setError] = useState(false);
  const [isChatDisabled, setIsChatDisabled] = useState(false);

  // Get setUpUser useCallback hook, this updates global state if we are logged in as an admin.
  const setUpUser = useSetUpUser();

  // Get timer hook for resetting test channel
  const resetTestChannelInterval = useTime(); // Refresh interval defaults to 1hr/3600000ms

  // Wrap spinner to prevent generating new react component on re-render.
  const wrappedSpinner = useCallback(
    () => <Spinner className="chat__spinner" />,
    [],
  );

  // Set up channel
  useEffect(() => {
    // If form is submitted, we have a channel id but channel has not been set up.
    if (isSubmitted && channelId) {
      // Build the channel
      const setUpChat = async () => {
        try {
          // Use client singleton to set up channel
          await setUpUser(isLoggedIn, userName, email, channelId);

          // Connect to our channel id.
          const chatChannel: StreamChannel = chatClient.channel(
            'livestream',
            channelId,
            { name: 'Art Talk' },
          );

          // Calling watch here will cause a 403 error if the channel is disabled
          // so then we can properly display the event ended message.
          await chatChannel.watch();

          // Update react
          setChannel(chatChannel);
        } catch (e) {
          console.error(e);
          if (e.status === 403 && e.message.includes('disabled')) {
            setIsChatDisabled(true);
            console.log('Chat has ended.');
          } else {
            setError(true);
          }
        }
      };

      setUpChat();
    }

    return () => {
      chatClient.disconnect();
      setChannel(null);
    };
  }, [
    isSubmitted,
    userName,
    email,
    channelId,
    isLoggedIn,
    setUpUser,
  ]);

  // Handle question being asked.
  // This is located in the chat rather than /poll as
  // the admin will administer the question, and /poll
  // components will not be rendered at this point.
  // This is effectively a mechanism for an admin to send a "secret message".
  useEffect(() => {
    if (channel && modPollQuestion) {
      channel.sendMessage({
        text: `${modPollQuestion.id}`,
        silent: true,
      });
    }

    return () => {
      if (channel && modPollQuestion) {
        channel.sendMessage({
          text: `${modPollQuestion.id}`,
          silent: true,
        });
      }
    };
  }, [channel, modPollQuestion]);

  // Handle resetting chat for test channel
  useEffect(() => {
    const resetChat = async (id) => {
      // Enable chat
      await restartChat(id);
      // Unpause chat
      channel && (await channel.update({ frozen: false }, { text: "Chat reset in progress..."}));
      // Clear chat
      channel && (await channel.truncate());
      // Add message about update
      console.log(`Chat reset completed at ${resetTestChannelInterval}!`)
      channel && channel.sendMessage({ text: `Chat reset at ${resetTestChannelInterval}.`})
    }
    if (channelId === "test") {
      resetChat(channelId);
    }
  }, [resetTestChannelInterval])

  const handleRestartChat = useCallback(async () => {
    channelId && (await restartChat(channelId));
  }, [channelId, restartChat]);

  // Mock messaging hook.
  // const counter = useRef(0);
  // useEffect(() => {
  // 	let sti: ReturnType<typeof setInterval>;

  // 	if (channel) {
  // 		sti = setInterval(() => {
  // 			channel.sendMessage({ text: `${counter.current}` });
  // 			// channel.sendMessage({ text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." });
  // 			counter.current = counter.current + 1;
  // 		}, 1000)
  // 	}

  // 	return () => {
  // 		if (sti) {
  // 			clearInterval(sti);
  // 		}
  // 	}
  // }, [channel]);

  // Add BEM modifier depending on user access level.
  let chatClassName = 'chat';
  if (isAdmin) {
    chatClassName = `chat--admin ${chatClassName}`;
  } else {
    chatClassName = `chat--user ${chatClassName}`;
  }

  return (
    <ThemeWrapper
      className={chatClassName}
      elementType="div"
      render={({ isDarkTheme }) =>
        isError ? (
          <div className="warning-wrapper">
            <Warning text="Error loading chat, please refresh the page." />
          </div>
        ) : isChatDisabled ? (
          <div className="warning-wrapper">
            <div>
              The chat for this event has ended.
            </div>

            {isAdmin && (
              <button
                className="btn-barnes btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  handleRestartChat();
                }}
              >
                Restart Chat
              </button>
            )}
          </div>
        ) : (
          <>
            {userName && typeof userName !== 'string' && (
              <UserInfo
                className="chat__user-info"
                userName={userName as GuestUserProfile}
              />
            )}
            <Chat
              client={chatClient}
              theme={!isDarkTheme ? 'light' : 'dark'}
            >
              {channelId && channel && (
                <Channel
                  channel={channel}
                  LoadingIndicator={wrappedSpinner}
                  acceptedFiles={[]}
                  maxNumberOfFiles={0}
                >
                  <Window>
                    <ChannelHeader />
                    {/* show Moderation Panel or Basic Chat */}
                    {isAdmin ? (
                      <ModerationPanel />
                    ) : (
                      <ChatContent isAdmin={isAdmin} />
                    )}
                  </Window>
                  <Thread />
                </Channel>
              )}
              {channel && !joinedChat && (
                <WelcomeMessage className="chat__welcome-message" />
              )}
            </Chat>
          </>
        )
      }
    />
  );
};

export { ChatModule as Chat };
