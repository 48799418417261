import { executePayloadRequest } from './httpService';
import { IVS } from 'aws-sdk';

/**
 * Get all AWS IVS channels.
 * @returns {IVS.ListChannelsResponse} - All AWS IVS channels associated with account.
 * @throws - @see httpService
 */
export const listIvsChannels: () => Promise<IVS.Channel[]> = async () => (
	executePayloadRequest<IVS.Channel[]>({
		url: '/api/ivs-channel/',
        method: 'GET',
	})
);
