import Filter from 'bad-words';
import { filteredWords } from "../util/customFilteredWords";

/**
 * Hook to set up profanity filter
 */
export const useFilter = () => {
    const filter = new Filter();
    filter.addWords(...filteredWords);

    return filter;
}