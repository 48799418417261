import {
    useState,
    useCallback,
    useEffect,
    useMemo
} from 'react';
import { GuestUserProfile } from '../../chat/util/guestUsers';
import { useUserNameLevel, UserNameLevel } from './useUserNameLevel';
import { Channel } from '../../../../../shared/channelPayloads';

/**
 * Check if string is parsable json.
 * @param stringified string to check if parsable json.
 * @returns {boolean} true if parseable.
 */
const isParsableJSON = (stringified: string): boolean => {
    try {
        JSON.parse(stringified);
    } catch {
        return false;
    }
    return true;
}

/**
 * Retrieve username from sessionStorage.
 * @returns {string} username.
 */
const getUserNameFromsessionStorage = (
    askForUserNameLevel: string
): GuestUserProfile | string | void => {
    const lastUserName = sessionStorage.getItem(`manualUserName--${askForUserNameLevel}`);
    
    // If json was stored, use this.
    if (isParsableJSON(lastUserName)) {
        return JSON.parse(lastUserName) as GuestUserProfile;
    }

    return lastUserName || undefined;
};

/**
 * Retrieve email from sessionStorage.
 * @returns {string} email.
 */
 const getEmailFromsessionStorage = (
): string | void => {
    const lastEmail = sessionStorage.getItem('email');
    
    // If json was stored, use this.
    if (isParsableJSON(lastEmail)) {
        return JSON.parse(lastEmail);
    }

    return lastEmail || undefined;
};

/**
 * Get and set username information.
 * @param {Channel['demographic'] | void} demographic - age demographic of channel.
 */
export const useUserName = (demographic: Channel['demographic'] | void): {
    askForUserNameLevel: {
        askForUserNameLevel: UserNameLevel,
        setIsOverrideUserNameLevel: React.Dispatch<React.SetStateAction<boolean>>;
    };
    email: {
        email: string;
        setEmail: React.Dispatch<React.SetStateAction<string>>;
    },
    userName: {
        userName: string | GuestUserProfile;
        setUserName: React.Dispatch<React.SetStateAction<string | GuestUserProfile>>;
    },
    isSubmitted: {
        isSubmitted: boolean;
        setIsSubmitted: (isSubmitted: boolean) => void;
    }
 } => {
    // Method for setting initial userNameLevel.
    const getUserNameLevel = useUserNameLevel(demographic);
    const [isOverrideUserNameLevel, setIsOverrideUserNameLevel] = useState(false);
    const askForUserNameLevel = useMemo(() => getUserNameLevel(isOverrideUserNameLevel), [getUserNameLevel, isOverrideUserNameLevel]);

    // Check our sessionStorage before defining submission state.
    const [userName, setUserName] = useState<string | GuestUserProfile>(getUserNameFromsessionStorage(askForUserNameLevel) || '');
    const [email, setEmail] = useState<string>(getEmailFromsessionStorage() || '');
    const [isSubmitted, setIsSubmitted] = useState(Boolean(userName) || false);

    // Wrap submit update in hook that also updates our sessionStorage.
    const submit = useCallback((isSubmitted: boolean) => {
        setIsSubmitted(isSubmitted);
    }, []);

    // Update our sessionStorage after submission.
    useEffect(() => {
        if (isSubmitted) {
            sessionStorage.setItem(
                `manualUserName--${askForUserNameLevel}`,
                typeof userName === 'string'
                    ? userName
                    : JSON.stringify(userName)
            );
            sessionStorage.setItem(
                'email',
                 email
            );
        }
    }, [userName, email, isSubmitted, askForUserNameLevel]);
    
    return {
        askForUserNameLevel: {
            askForUserNameLevel: demographic ? askForUserNameLevel : undefined,
            setIsOverrideUserNameLevel,
        },
        email:{
            email,
            setEmail,
        },
        userName: {
            userName,
            setUserName,
        },
        isSubmitted: {
            isSubmitted,
            setIsSubmitted: submit,
        }
    };
};
