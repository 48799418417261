import React, {
    useEffect,
    useState,
    useMemo
} from 'react';
import { useStreamChannel } from '../../shared/hooks/useStreamChannel';
import { AdminPrivilegesChatWrapper } from './adminPrivilegesChatWrapper';
import { useActivePoll } from './hooks/useActivePoll';

/**
 * @todo REFACTOR.
 * 
 * THIS IS CNP DIRECTLY FROM OUR POLLMA.PE SKIN.
 * 
 */
const PollResults: React.FC = () => {
    const poll = useActivePoll();
    const { messages } = useStreamChannel();
    const [votes, setVotes] = useState<{ [uid: string]: number }>({});

    // Create hashMap of poll answers
    const pollAnswersMap = useMemo(() => (
        poll ?
            poll.poll_options
                .reduce((acc, { name, uid }, i) => ({
                    ...acc, [name.toLowerCase()]: uid, [i + 1]: uid
                }), {})
            : {}
    ), [poll]);

    // If there is a poll, feed updates.
    useEffect(() => {
        if (poll) {
            const votes = messages
                .reduce((acc, { text, silent }) => {
                    // If message is silent, reset our responses.
                    if (silent) {
                        return { responses: {}};
                    } else {
                        // Find uid in our hashmap
                        const uid = pollAnswersMap[text.toLowerCase()];

                        // If we have a uid, increment in responses.
                        if (uid) {
                            return {
                                responses: {
                                    ...acc.responses,
                                    [uid]: acc.responses[uid] ? acc.responses[uid] + 1 : 1,
                                }
                            }
                        }
                    }

                    // Catch-all default return for accumulator.
                    return acc;
                }, { responses: {} });
            
            setVotes(votes.responses);
        }
    }, [poll, messages, pollAnswersMap]);

    const totalVotes = Object.values(votes).reduce((acc, next) => acc + next, 0);

    return (
        <div className="poll-results-wrapper">
            <div id="content">
                <div id="app" className="base page-overlay">
                    <div className="overlay">
                        <div id="poll" className="stream-poll inOverlay custom active topLeft">
                            <div className="border"></div>
                            <div className="inner">
                                <ul className="options">
                                    {poll && <li className="question">{poll.title}</li>}
                                    {
                                        poll && poll.poll_options.map((option, i) => {
                                            const percentage = `${
                                                totalVotes
                                                    ? Math.floor((votes[option.uid] * 100 || 0)/totalVotes)
                                                    : 0
                                                }%`;
                                            
                                            return (
                                                <li
                                                    key={option.uid}
                                                    className="option highest"
                                                >
                                                    <span className="number">{i + 1}</span>
                                                    <span className="text">{option.name}</span><span className="procent">
                                                        {/**
                                                         * If we have any votes, show what percentage on map this answer has.
                                                         * If we have no votes, display 0%.
                                                         */}
                                                        {percentage}
                                                    </span>
                                                    <div className="bar">
                                                        <div className="bar-inner" style={{ width: percentage }}></div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    
                                </ul>
                                <div className="metaInfo">
                                    <div className="total">
                                        <label>
                                            Unique votes:{" "}
                                    </label>
                                    {Boolean(poll) && <strong>{totalVotes}</strong>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * Expose admin stream messages to child.
 */
const WrappedPollResults = () => (
    <AdminPrivilegesChatWrapper>
        <PollResults />
    </AdminPrivilegesChatWrapper>
);

export { WrappedPollResults as PollResults };
