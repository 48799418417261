import React, { useEffect, useState, useContext } from 'react';
import { ThemeWrapper } from '../../shared/themeWrapper';
import { ChannelContext } from '../../../contexts/channelContext';
import { UserContext } from '../../../contexts/userContext';
import { AdminContext } from '../../../contexts/adminContext';

const ANIMATION_TIME = 400;

type WelcomeMessageProps = {
  className?: string;
};

export const WelcomeMessage: React.FC<WelcomeMessageProps> = ({
  className,
}) => {
  const { welcomeMessage } = useContext(ChannelContext);
  const { joinedChat, setJoinedChat } = useContext(UserContext);
  const { isAdmin } = useContext(AdminContext);

  useEffect(() => {
    let sto: ReturnType<typeof setTimeout>;

    if (joinedChat) {
      setTimeout(() => {}, ANIMATION_TIME);
    }

    return () => {
      if (sto) {
        clearTimeout(sto);
      }
    };
  });

  useEffect(() => {
    if (welcomeMessage === '' || isAdmin) {
      setJoinedChat(true);
    }
  }, []);

  let welcomeMessageClassName = `${className} welcome-message`;
  if (joinedChat || isAdmin) {
    welcomeMessageClassName = `${welcomeMessageClassName} welcome-message--hidden welcome-message`;
  }

  return (
    <ThemeWrapper
      elementType="article"
      className={welcomeMessageClassName}
    >
      <div className="welcome-message__description">
        <h4>Welcome!</h4>
        <p>{welcomeMessage}</p>
      </div>
      <button
        className="btn-barnes welcome-message__ok"
        onClick={() => {
          setJoinedChat(true);
        }}
      >
        OK
      </button>
    </ThemeWrapper>
  );
};
