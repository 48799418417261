import guestUserSrcs from '../../../../assets/userIconsLarge';

export type GuestUserProfile = {
  icon: string;
  userName: string;
  collectionUrl: string;
  name: string;
  organization: string;
};

export const guestUserAvatars: Omit<
  GuestUserProfile,
  'name' | 'organization'
>[] = [
  {
    userName: 'Apple Vendor',
    icon: guestUserSrcs.icon1,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5776`,
  },
  {
    userName: 'Bow Guard',
    icon: guestUserSrcs.icon2,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6428`,
  },
  {
    userName: 'Cup of Chocolate',
    icon: guestUserSrcs.icon3,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7007`,
  },
  {
    userName: 'Felix Hippolyte-Lucas',
    icon: guestUserSrcs.icon4,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6356`,
  },
  {
    userName: 'Marie Murer',
    icon: guestUserSrcs.icon5,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5672`,
  },
  {
    userName: 'Painted Canister',
    icon: guestUserSrcs.icon6,
    collectionUrl: `https://collection.barnesfoundation.org/objects/8158`,
  },
  {
    userName: 'Philosophical Thought',
    icon: guestUserSrcs.icon7,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5695`,
  },
  {
    userName: 'Portrait of a Man',
    icon: guestUserSrcs.icon8,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4747`,
  },
  {
    userName: 'Saint Lawrence',
    icon: guestUserSrcs.icon9,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6517`,
  },
  {
    userName: 'The Past and The Present',
    icon: guestUserSrcs.icon10,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5695`,
  },
  {
    userName: 'Unpleasant Surprise',
    icon: guestUserSrcs.icon11,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5434`,
  },
  {
    userName: 'Face Mask',
    icon: guestUserSrcs.icon12,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6728`,
  },
  {
    userName: 'Card Player',
    icon: guestUserSrcs.icon13,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6992`,
  },
  {
    userName: 'Card Player',
    icon: guestUserSrcs.icon14,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6992`,
  },
  {
    userName: 'Card Player',
    icon: guestUserSrcs.icon15,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6992`,
  },
  {
    userName: 'Movement',
    icon: guestUserSrcs.icon16,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6598`,
  },
  {
    userName: 'A Skull',
    icon: guestUserSrcs.icon17,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4712`,
  },
  {
    userName: 'A Rabbit',
    icon: guestUserSrcs.icon18,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5458`,
  },
  {
    userName: 'Pears',
    icon: guestUserSrcs.icon19,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7001`,
  },
  {
    userName: 'A Woman',
    icon: guestUserSrcs.icon20,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5533`,
  },
  {
    userName: 'Weather Vane',
    icon: guestUserSrcs.icon21,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5916`,
  },
  {
    userName: 'Cuff Bracelet',
    icon: guestUserSrcs.icon22,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6851`,
  },
  {
    userName: 'Flowers',
    icon: guestUserSrcs.icon23,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6324`,
  },
  {
    userName: 'Parrot',
    icon: guestUserSrcs.icon24,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6107`,
  },
  {
    userName: 'Bird',
    icon: guestUserSrcs.icon25,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6519`,
  },
  {
    userName: 'Pull Escutcheon',
    icon: guestUserSrcs.icon26,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7070`,
  },
  {
    userName: 'Pewter Flagon',
    icon: guestUserSrcs.icon27,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6336`,
  },
  {
    userName: 'Chain Link',
    icon: guestUserSrcs.icon28,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4924`,
  },
  {
    userName: 'Pink Rose',
    icon: guestUserSrcs.icon29,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7088`,
  },
  {
    userName: 'Iron Halberd',
    icon: guestUserSrcs.icon30,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4921`,
  },
  {
    userName: 'Bronze Horse',
    icon: guestUserSrcs.icon31,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6675`,
  },
  {
    userName: 'Flower Piece',
    icon: guestUserSrcs.icon32,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6324`,
  },
  {
    userName: 'Bird Facing Left',
    icon: guestUserSrcs.icon33,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6519`,
  },
  {
    userName: 'Wooden Parrot',
    icon: guestUserSrcs.icon34,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6107`,
  },
  {
    userName: 'Door Hinge',
    icon: guestUserSrcs.icon35,
    collectionUrl: `https://collection.barnesfoundation.org/objects/8036`,
  },
  {
    userName: 'Covered Jar',
    icon: guestUserSrcs.icon36,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5278`,
  },
  {
    userName: 'Chaim Soutine',
    icon: guestUserSrcs.icon37,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5535`,
  },
  {
    userName: 'Georges Braque',
    icon: guestUserSrcs.icon38,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5112`,
  },
  {
    userName: 'Windsor Armchair',
    icon: guestUserSrcs.icon39,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4705`,
  },
  // {
  //     userName: 'Andiron',
  //   icon: guestUserSrcs.icon40,
  //   collectionUrl: `https://collection.barnesfoundation.org/objects/5874`,
  // },
  {
    userName: 'Charles Demuth',
    icon: guestUserSrcs.icon41,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6293`,
  },
  {
    userName: 'Fluid Lamp',
    icon: guestUserSrcs.icon42,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5862`,
  },
  {
    userName: 'Food Bowl',
    icon: guestUserSrcs.icon43,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4499`,
  },
  {
    userName: 'Candlestick',
    icon: guestUserSrcs.icon44,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5286`,
  },
  {
    userName: 'Side Chair',
    icon: guestUserSrcs.icon45,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5854`,
  },
  {
    userName: 'Iron Fork',
    icon: guestUserSrcs.icon46,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7058`,
  },
  {
    userName: 'Iron Hook',
    icon: guestUserSrcs.icon47,
    collectionUrl: `https://collection.barnesfoundation.org/objects/8030`,
  },
  {
    userName: 'Stand',
    icon: guestUserSrcs.icon48,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6104`,
  },
  {
    userName: 'Keyhole Escutcheon',
    icon: guestUserSrcs.icon49,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7116`,
  },
  {
    userName: 'Ceremonial Axe',
    icon: guestUserSrcs.icon50,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7555`,
  },
  {
    userName: 'Oil Lamp',
    icon: guestUserSrcs.icon51,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7854`,
  },
  {
    userName: 'Ring',
    icon: guestUserSrcs.icon52,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4577`,
  },
  {
    userName: 'Stool',
    icon: guestUserSrcs.icon53,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4728`,
  },
  {
    userName: 'Door Pull',
    icon: guestUserSrcs.icon54,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7665`,
  },
  {
    userName: 'Tin Coffeepot',
    icon: guestUserSrcs.icon55,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5261`,
  },
  {
    userName: 'Jean Renoir',
    icon: guestUserSrcs.icon56,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5246`,
  },
  {
    userName: 'Water Jar',
    icon: guestUserSrcs.icon57,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4585`,
  },
  {
    userName: 'van Goyen',
    icon: guestUserSrcs.icon58,
    collectionUrl: `https://collection.barnesfoundation.org/objects/6598`,
  },
  {
    userName: 'Door Knocker',
    icon: guestUserSrcs.icon59,
    collectionUrl: `https://collection.barnesfoundation.org/objects/5897`,
  },
  {
    userName: 'Storage Jar',
    icon: guestUserSrcs.icon60,
    collectionUrl: `https://collection.barnesfoundation.org/objects/4510`,
  },
  {
    userName: 'Sign For a Locksmith',
    icon: guestUserSrcs.icon61,
    collectionUrl: `https://collection.barnesfoundation.org/objects/7399`,
  },
];
