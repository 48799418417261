import { useState, useMemo, useEffect, useContext } from 'react';
import {  useParams, useHistory } from 'react-router-dom'
import { isCurrentlyBroadcasting } from '../../../shared/util/convertToChannelMap';
import { getChannel } from '../../../../services/channelService';
import { Channel } from '../../../../../shared/channelPayloads';
import { ChannelContext } from '../../../../contexts/channelContext'

/**
 * Get channel information from server
 * and determine if channel is currently broadcasting.
 */
export const useChannel = () => {
    const history = useHistory();
    const { slug } = useParams<{ slug: string }>(); // /:slug

    // Channel information
    const [channel, setChannel] = useState<Channel | void>(null);
    const broadcastTimeStatus = useMemo(() => channel ? isCurrentlyBroadcasting(channel) : null, [channel]);
    const {
      setSlowMode,
      setCooldown,
      setSimpleChat,
      setWelcomeMessage,
      setDemographic,
    } = useContext(ChannelContext);

    // Fetch channel on cDM
    useEffect(() => {
      const fetchChannel = async () => {
        try {
          const channel = await getChannel(slug);

          if (typeof channel === "string") {
            throw new Error("No channel from server matches this slug.");
          }

          setChannel(channel);
          setSlowMode(channel.slow_mode);
          channel.slow_mode && setCooldown(channel.cooldown);
          setSimpleChat(channel.simple_chat);
          setWelcomeMessage(channel.welcome_message);
          setDemographic(channel.demographic);
        } catch (e) {
          console.error(e);

          history.push("/", {
            error: "We couldn't find a channel with that name.",
          });
        }
      };

      fetchChannel();
    }, [
      history,
      slug,
      setCooldown,
      setSimpleChat,
      setWelcomeMessage,
      setSlowMode,
      setDemographic,
    ]);

    return {
        broadcastTimeStatus,
        channel,
    };
};
