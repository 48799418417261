import { ChannelInputForm } from '../channelManager';

/**
 * Strip spaces, colons and parse remaining to int for a time string.
 * @param {string} time - string arg in format "12:23 PM"
 */
const parseTime = time => parseInt(time.split(' ')[0].replace(':', ''));


/**
 * Validates value to make sure value is not in the list of undefined values
 * @param {any} - any value
 */
const isUndefined = value => [undefined, "", null].includes(value)

/**
 * Extract form validation.
 * @param {ChannelInputForm} createChannelForm - ChannelInput combined with date from form for valdiating.
 * @returns {string} error message, returns '' if there is no error.
 */
export const validateCreateChannelForm = (createChannelForm: ChannelInputForm) => {
    // Is adult may evaluate to false, and is automatically set,
    // so we do not want to account for this in our validation.
    // All other fields excluded from ...rest are optional.
    const {
        // Will be undefined, may be boolean.
        id,
        enabled,

        // Optional
        description,
        cooldown,

        ...rest
    } = createChannelForm;

    // First, check to see if all of our form has been filled out.
    if (Object.values(rest).every(isUndefined)) {
        return 'All required fields are not filled out.';
    // Then, check to see if time range is valid
    } else if (
        parseTime(createChannelForm.availability_start) >
        parseTime(createChannelForm.availability_end)
    ) {
        return 'Start time must be before end time.';
            
    // Then, check to make sure that if slow mode is enabled, cooldown has been entered
    } else if (rest.slow_mode && cooldown < 10) {

        return "Cooldown must be set when Slow Mode is enabled.";
    // Finally, set error message to none.
    // This is sometimes used to evaluate to falsy, so 
    // the empty string here is important.
    } else {
        return '';
    }
};
