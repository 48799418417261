import React, {
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import { NotOnAirModal } from './modals/notOnAirModal';
import { NamedUserModal } from './modals/namedUserModal';
import { AvatarUserModal } from './modals/avatarUserModal';
import { Description } from '../description';
import { ModPollQuestions } from '../modPollQuestions';
import { Chat } from '../chat/chat';
import { SiteHelmet } from '../../shared/siteHelmet';
import { ThemeWrapper } from '../../shared/themeWrapper';
import { AuthContext } from '../../../contexts/authContext';
import { AdminContext } from '../../../contexts/adminContext';
import { ChannelContext } from '../../../contexts/channelContext';
import { useUserName } from './hooks/useUserName';
import { useChannel } from './hooks/useChannel';
import { usePollQuestions } from '../../shared/hooks/usePollQuestions';
import { listChannelPolls } from '../../../services/pollService';
import { Poll } from '../../../../shared/channelPollPayloads';
import { VideoStreamModule } from '../videoStreamModule/videoStreamModule';
import { ClosedCaptions } from './closedCaptions';
import {
  MINOR,
  NAMED,
  ADMIN,
  AVATAR,
  PRESENT,
  TEST,
} from '../../../constants';

export const Stream = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { isAdmin } = useContext(AdminContext);
  const { simpleChat, demographic } = useContext(ChannelContext);

  const { broadcastTimeStatus, channel } = useChannel();
  const [modPollQuestion, setModPollQuestion] = useState<Poll | void>(
    null,
  );

  const [showModal, setShowModal] = useState(true);

  const {
    askForUserNameLevel: {
      askForUserNameLevel,
      setIsOverrideUserNameLevel,
    },
    email: { email, setEmail },
    userName: { userName, setUserName },
    isSubmitted: { isSubmitted, setIsSubmitted },
  } = useUserName(channel ? channel.demographic : undefined);

  // Boolean indicator if this channel is currently on air.
  // Currently the logic is backwards and returns false if the channel is on air.
  const isBroadcasting = useMemo(
    () => {
      return channel && broadcastTimeStatus === TEST ? false :
        broadcastTimeStatus &&
        broadcastTimeStatus !== PRESENT
    },
    [channel, broadcastTimeStatus]
  );

  // Set up polls for authenticated users.
  const fetchPolls = useCallback(
    async () => (channel ? listChannelPolls(channel.id) : []),
    [channel],
  );
  const [pollQuestions, setPollQuestions] = usePollQuestions(
    Boolean(isLoggedIn && channel),
    fetchPolls,
  );

  // Submission callback for modal
  const modalSubmission = useCallback(
    (userName, email = undefined) => {
      setUserName(userName);
      if (email) {
        setEmail(email);
      }
      setIsSubmitted(true);
    },
    [setUserName, setIsSubmitted],
  );

  useEffect(() => {
    setShowModal(
      !userName && !!channel && !isSubmitted && !isBroadcasting,
    );
  }, [userName, channel, isSubmitted, isBroadcasting]);

  return (
    <ThemeWrapper className="stream" elementType="main">
      <SiteHelmet channel={channel} isBroadcasting={isBroadcasting} />

      {/** Overlay and modal to display if event is not active. */}
      {channel && isBroadcasting && !isLoggedIn && (
        <NotOnAirModal
          isBroadcasting={isBroadcasting}
          broadcastTimeStatus={broadcastTimeStatus}
          channel={channel}
        />
      )}

      {showModal &&
        (askForUserNameLevel === NAMED || simpleChat) &&
        askForUserNameLevel !== ADMIN && (
          <NamedUserModal
            submit={modalSubmission}
            isMinor={demographic === MINOR}
          />
        )}

      {showModal && askForUserNameLevel === AVATAR && !simpleChat && (
        <AvatarUserModal submit={modalSubmission} />
      )}

      {/** Main stream content */}
      <div className="stream__content">
        {/** Display the video stream */}
        <section className="stream__video">
          {/** Video from IVS, display only when this is active and we have the channel. */}
          <VideoStreamModule
            playbackUrl={
              (broadcastTimeStatus === PRESENT || broadcastTimeStatus === TEST || isLoggedIn) &&
                channel
                ? channel.ivs_playback_url
                : ''
            }
          />
          {(broadcastTimeStatus === PRESENT || broadcastTimeStatus === TEST) && channel && (
            <ClosedCaptions channel={channel} />
          )}

          <div className="stream__description">
            {/* Toggle user for admin */}
            {isLoggedIn && channel && (
              <button
                className="stream__user-button"
                onClick={() =>
                  setIsOverrideUserNameLevel(
                    Boolean(askForUserNameLevel === ADMIN),
                  )
                }
              >
                {askForUserNameLevel === ADMIN ? 'User' : 'Admin'}
              </button>
            )}

            {/** Channel description -- DESKTOP */}
            {channel && (
              <Description
                channel={channel}
                className="description description--desktop"
              />
            )}

            {/** Poll section for admins */}
            {Boolean(
              isAdmin && pollQuestions && pollQuestions.length,
            ) && (
                <ModPollQuestions
                  modPollQuestion={modPollQuestion}
                  pollQuestions={pollQuestions || []}
                  setPollQuestions={setPollQuestions}
                  setModPollQuestion={setModPollQuestion}
                />
              )}
          </div>
        </section>

        {/** App chat display only when this is active and we have the channel. */}
        <section className="stream__chat">
          <Chat
            isSubmitted={isSubmitted || askForUserNameLevel === ADMIN}
            userName={
              askForUserNameLevel !== ADMIN && isSubmitted && userName
            }
            email={askForUserNameLevel !== ADMIN && email} // trying this
            channelId={
              (broadcastTimeStatus === PRESENT || broadcastTimeStatus === TEST || isLoggedIn) &&
                channel
                ? channel.streamchat_channel_id
                : null
            }
            modPollQuestion={modPollQuestion}
          />
        </section>

        {/** Channel description -- MOBILE */}
        {channel && (
          <Description
            channel={channel}
            className="description description--mobile"
          />
        )}
      </div>
    </ThemeWrapper>
  );
};
