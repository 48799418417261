import React, { useCallback, useMemo, useState } from 'react';
import { Message } from 'stream-chat';
import { BaseEmoji, CustomEmoji } from 'emoji-mart';
import emojiData from 'emoji-mart/data/all.json';
import { chatClient } from './util/chatClient';
import { banUsers } from '../../../services/channelService';
import { customEmojis } from './util/customEmojis';

// Make hash map of custom emojis.
const customEmojiMap: { [key: string]: CustomEmoji } =
  customEmojis.reduce(
    (acc, emoji) => ({
      ...acc,
      [emoji.name]: emoji,
    }),
    {},
  );

type ReactionName = 'happy' | 'like' | 'surprise' | 'paint';
const REACTIONS: { type: ReactionName; emoji: string }[] = [
  { type: 'happy', emoji: '🙂' },
  { type: 'like', emoji: '👍' },
  { type: 'surprise', emoji: '😮' },
  { type: 'paint', emoji: '🎨' },
];

type MessageProps = {
  message: Message;
  isAdmin: boolean;
  channelId: string | void;
  userId: string;
  reactToMessage: (
    messageId: string,
    type: string,
    hasReactionFromUser: boolean,
  ) => Promise<void>;
  simpleChat: boolean;
};
export const MessageListItem: React.FC<MessageProps> = ({
  message,
  isAdmin,
  channelId,
  userId,
  reactToMessage,
  simpleChat,
}) => {
  const { user, id, text, own_reactions } = message;
  const isAdminMessage = user.name === 'Admin';
  const [isBanned, setIsBanned] = useState(user.banned);

  let messageClassName = 'chat__message';
  if (isAdminMessage) {
    messageClassName = `${messageClassName} chat__message--admin`;
  }

  // Message reactions count map.
  const reactionsMap = useMemo(
    () =>
      REACTIONS.reduce(
        (acc, { type }) => ({
          ...acc,
          [type]:
            message.reaction_counts && message.reaction_counts[type]
              ? message.reaction_counts[type]
              : 0,
        }),
        {} as { [key in ReactionName]: number },
      ),
    [message],
  );
  const reactionsTotal = Object.values(reactionsMap).reduce(
    (acc, next) => acc + next,
    0,
  );

  // Map out all reactions ahead of reaction for lower time complexity.
  // We access the user id and type key of this object to determine if a user
  // has already liked an item.
  const hasReactionFromUserMap = useMemo(
    () =>
      own_reactions.reduce(
        (acc, { user_id, type }) => ({
          ...acc,
          [user_id]: {
            ...(acc[user_id] || {}),
            [type]: true,
          },
        }),
        {},
      ),
    [own_reactions],
  );

  const reactions = useMemo(
    () =>
      REACTIONS.map(({ emoji, type }) => {
        const reactionCount = reactionsMap[type];

        let reactionClassName = 'chat__reaction';
        if (reactionCount) {
          reactionClassName = `${reactionClassName} chat__reaction--active`;
        }

        // If this reaction type already has a reaction from this user.
        const hasReactionFromUser = Boolean(
          hasReactionFromUserMap[userId] &&
            hasReactionFromUserMap[userId][type],
        );

        return (
          <button
            key={`${id}${type}`}
            className={reactionClassName}
            onClick={() =>
              reactToMessage(id, type, hasReactionFromUser)
            }
          >
            {emoji}
            <span>{reactionCount}</span>
          </button>
        );
      }),
    [
      id,
      userId,
      hasReactionFromUserMap,
      reactionsMap,
      reactToMessage,
    ],
  );

  // Administrative callbacks.
  // Delete a message.
  const deleteMessage = useCallback(async () => {
    try {
      await chatClient.deleteMessage(id);
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  // Ban a user
  const banUser = useCallback(async () => {
    try {
      if (channelId && user?.id) {
        await banUsers(channelId, [user.id]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsBanned(true);
    }
  }, [channelId, user]);

  let chatReactionWrapperClassName = 'chat__reaction-wrapper';
  if (reactionsTotal && !simpleChat) {
    chatReactionWrapperClassName = `${chatReactionWrapperClassName} chat__reaction-wrapper--active`;
  }

  // Regex for :emoji-syntax:
  // Not currrently used.
  // :[^:\s]*(?:::[^:\s]*)*:
  const parsedText = useMemo(() => {
    // If text does not include colon, we do not need to parse.
    if (!text.includes(':')) {
      return text;
    }

    // Otherwise, split text by colons, then parse.
    return text
      .split(':')
      .filter(Boolean)
      .map((text, i) => {
        const key = `${id}${text}${i}`;

        // First, search emojiIndex, if there is a result, use it.
        // Otherwise, default to accessing customEmoji hash map.
        // If this DNE, it will default to undefined.
        // const emojiIndexResult = emojiIndex.search(text);
        // const foundEmoji = emojiIndexResult.length ? emojiIndexResult[0] : customEmojiMap[text];

        // Search for
        const foundEmoji =
          emojiData.emojis[text] || customEmojiMap[text];

        // If this is a custom emoji, we will want to use it's image URL.
        // Return the following JSX element.
        if (foundEmoji && (foundEmoji as CustomEmoji).imageUrl) {
          const customEmoji = foundEmoji as CustomEmoji;

          return (
            <img
              key={key}
              alt={customEmoji.name}
              title={customEmoji.name}
              className="chat__custom-emoji"
              src={customEmoji.imageUrl}
            />
          );
        }

        // Otherwise, this is either a default emoji or just text.
        // First check for native emoji, then return text.
        return (
          <React.Fragment key={key}>
            {foundEmoji && (foundEmoji as BaseEmoji).native
              ? (foundEmoji as BaseEmoji).native
              : text}
          </React.Fragment>
        );
      });
  }, [text, id]);

  return (
    <div className={messageClassName}>
      {/** Administrative actions */}
      {isAdmin && (
        <>
          <button className="chat__delete" onClick={deleteMessage}>
            Delete
          </button>
          {!isAdminMessage && (
            <>
              {isBanned ? (
                <span className="chat__muted">
                  <i>Muted</i>
                </span>
              ) : (
                <button className="chat__delete" onClick={banUser}>
                  Mute
                </button>
              )}
            </>
          )}
        </>
      )}

      {Boolean(user.image && !isAdminMessage && !simpleChat) && (
        <div className="chat__user-avatar-wrapper">
          <img
            alt={user.avatarName || 'custom avatar'}
            className="chat__user-avatar"
            src={user.image}
          />
        </div>
      )}
      {/** User actions */}
      {!simpleChat && (
        <div className={chatReactionWrapperClassName}>
          {reactions}
        </div>
      )}

      {/** User name */}
      <div className="chat__username">{user.name}:</div>

      {/** User message */}
      <span className="chat__message-text">{parsedText}</span>
    </div>
  );
};
