import React, { useRef, ReactHTML } from 'react';
import { useLocation, Link } from 'react-router-dom';
import DayPicker from 'react-day-picker';
import TimePicker from 'react-time-picker';
import { FormSubmission } from './formSubmission';
import { FormItem } from './formItem';
import { StandardInput } from './standardInput';
import { IvsSelect } from './ivsSelect';
import { PollQuestions } from '../../shared/pollQuestions';
import { ThemeWrapper } from '../../shared/themeWrapper';
import { Toggle } from '../../shared/toggle';
import { BackLink } from '../../shared/backLink';
import { useForm, getInitialFormState } from './hooks/useForm';
import { useErrorMessageWrapper } from './hooks/useErrorMessageWrapper';
import { usePollQuestions } from '../../shared/hooks/usePollQuestions';
import { useAuthRequest } from '../../../contexts/authContext';
import { createOrModifyChannel } from './util/submitCreateChannel';
import {
  createChannel,
  updateChannel,
  deleteChannel,
  downloadLogs,
  downloadParticipants,
} from '../../../services/channelService';
import { listChannelPolls } from '../../../services/pollService';
import {
  ChannelInput,
  Channel,
} from '../../../../shared/channelPayloads';
import { CURRENT_DATE, ADULT, MINOR } from '../../../constants';
import 'react-day-picker/lib/style.css'; // # TODO => Move this into styling.
import { isContext } from 'vm';

// Form shape will need to have separate date object.
// This will be converted on submission to part of ChannelInput.availability_start and
// ChannelInput.availability_end.
export type ChannelInputForm = ChannelInput & { date: Date };

/**
 * Admin panel section to create event.
 */
export const ChannelManager = () => {
  // We are in edit mode if there is a channel in state.
  const location = useLocation<{ channel?: Channel }>();
  const isEditMode = Boolean(location.state?.channel);

  // Wrap HTTP reqs in auth wrapper.
  const createOrModifyChannelAuth = useAuthRequest(
    createOrModifyChannel,
  );
  const deleteCreateChannelAuth = useAuthRequest(deleteChannel);
  const getPollQuestions = useAuthRequest(listChannelPolls);
  const getLogs = useAuthRequest(downloadLogs);
  const getParticipants = useAuthRequest(downloadParticipants);

  // Set up form, poll questions
  const {
    channelForm: [createChannelForm, setCreateChannelForm],
    formErrorMessage,
    setFormErrorMessage,
    updateCreateChannelFormByKey,
    updateCreateChannelInput,
  } = useForm(getInitialFormState(location.state?.channel));
  const [pollQuestions, setPollQuestions] = usePollQuestions(
    isEditMode,
    async () => getPollQuestions(createChannelForm.id),
  );

  // Set ref to first value of channelForm[0]
  const linkTo = useRef(createChannelForm.slug);

  // Wrap submitCreateChannelAuth and deleteCreateChannelAuth to handle for error presentation.
  const submission = {
    // Spread form and append poll questions
    ...createChannelForm,

    // Filter any poll questions that do not have a title.
    pollQuestions: pollQuestions.filter(
      (pollQuestion) => pollQuestion.title,
    ),
  };
  const submitChannel = useErrorMessageWrapper(
    async () => createOrModifyChannelAuth(submission, createChannel),
    setFormErrorMessage,
  );
  const submitUpdateChannel = useErrorMessageWrapper(
    async () => createOrModifyChannelAuth(submission, updateChannel),
    setFormErrorMessage,
  );
  const submitDeleteChannel = useErrorMessageWrapper(
    async () => deleteCreateChannelAuth(createChannelForm.id),
    setFormErrorMessage,
  );

  const isTestChannel = createChannelForm.slug === 'test';

  //////////////////
  // Render logic //
  //////////////////
  // Standard Inputs
  // Reduce these to an object and access via keys in render.
  const standardInputs = (
    [
      { label: 'URL Slug', key: 'slug', elementType: 'input' },
      { label: 'Title', key: 'title', elementType: 'input' },
      { label: 'Subtitle', key: 'subtitle', elementType: 'input' },
      {
        label: 'Description',
        key: 'description',
        elementType: 'textarea',
      },
      {
        label: '',
        key: 'welcome_message',
        elementType: 'textarea',
      },
    ] as {
      label: string;
      key: keyof ChannelInputForm;
      elementType: keyof ReactHTML;
    }[]
  ).reduce((acc, { key, ...rest }) => {
    const isDisabled = Boolean(key === 'slug' && isEditMode);

    return {
      ...acc,
      [key]: (
        <StandardInput
          {...rest}
          key={key}
          value={createChannelForm[key] as string}
          onChange={updateCreateChannelInput(
            key as keyof ChannelInput,
          )}
          disabled={isDisabled}
        />
      ),
    };
  }, {} as { [key in keyof ChannelInputForm]?: JSX.Element });

  // Time range, these are adjacent so can just be an array.
  const eventTimes = [
    { label: 'Start Time', key: 'availability_start' },
    { label: 'End Time', key: 'availability_end' },
  ].map(({ label, key }) => (
    <FormItem key={key} label={label}>
      <TimePicker
        className="create-channel__form-time-picker"
        disableClock
        value={createChannelForm[key]}
        onChange={(time) =>
          setCreateChannelForm((form) => ({ ...form, [key]: time }))
        }
        disabled={isTestChannel}
      />
    </FormItem>
  ));

  return (
    <ThemeWrapper className="create-channel" elementType="section">
      {/** Header section */}
      <BackLink>← Back</BackLink>

      <header className="create-channel__header-section">
        <h1 className="create-channel__header">
          {!isEditMode
            ? 'Create New Channel'
            : 'Edit Existing Channel'}
        </h1>
        <p className="create-channel__detail">
          {!isEditMode
            ? 'Complete the following form and submit to create a new channel.'
            : 'Modify any information for an existing channel.'}
        </p>

        {/** Link to current channel */}
        {isEditMode && (
          <Link className="a-brand-link" to={`/${linkTo.current}`}>
            View Channel
          </Link>
        )}

        {/** Download logs */}
        {isEditMode && (
          <div className="create-channel__form-flex-row">
            <button
              className="btn-barnes create-channel__form-submit-button create-channel__form-submit-button--margin-top"
              onClick={() => getLogs(createChannelForm.slug)}
            >
              Download Chat
            </button>

            <button
              className="btn-barnes create-channel__form-submit-button create-channel__form-submit-button--margin-top"
              onClick={() => getParticipants(createChannelForm.slug)}
            >
              Download Participant List
            </button>
          </div>
        )}
      </header>

      {/** Form, all updates are passed to reducer. */}
      <form
        className="create-channel__form"
        onSubmit={(e) => e.preventDefault()}
      >
        {/** URL slug and channel id */}
        {standardInputs.slug}

        {/** Event date */}
        <FormItem label="Date">
          <DayPicker
            selectedDays={
              createChannelForm.date && [createChannelForm.date]
            }
            onDayClick={(date) =>
              isTestChannel
                ? null
                : updateCreateChannelFormByKey({ date })
            }
            month={
              createChannelForm.date
                ? createChannelForm.date
                : CURRENT_DATE
            }
            disabledDays={(day) =>
              isTestChannel
                ? true
                : day.getDate() < CURRENT_DATE.getDate() &&
                  day.getMonth() < CURRENT_DATE.getMonth() + 1
            }
          />
        </FormItem>

        {/** Event times */}
        <div className="create-channel__form-flex-row">
          {eventTimes}
        </div>

        <FormItem label="Age Group">
          <div className="create-channel__form-toggle-wrapper">
            Adult
            <Toggle
              className="create-channel__form-toggle"
              isOn={createChannelForm.demographic === ADULT}
              onClick={() =>
                setCreateChannelForm((form) => ({
                  ...form,
                  demographic:
                    form.demographic === ADULT ? MINOR : ADULT,
                }))
              }
            />
            Child
          </div>
        </FormItem>

        <div className="create-channel__form-flex-row">
          <FormItem label="Slow Mode">
            <div className="create-channel__form-toggle-wrapper">
              On
              <Toggle
                className="create-channel__form-toggle"
                isOn={createChannelForm.slow_mode}
                onClick={() =>
                  setCreateChannelForm((form) => ({
                    ...form,
                    slow_mode: !form.slow_mode,
                  }))
                }
              />
              Off
            </div>
          </FormItem>

          <FormItem label="Slow Mode Cooldown">
            <span>(in seconds)</span>
            <input
              className="create-channel__form-input create-channel__form-input--number"
              type="number"
              value={createChannelForm.cooldown}
              disabled={!createChannelForm.slow_mode}
              onChange={updateCreateChannelInput(
                'cooldown' as keyof ChannelInput,
              )}
              min="10"
            />
          </FormItem>
        </div>

        {/* Allow chat message reactions */}
        <FormItem label="Simple Chat">
          <p>
            Turning on "Simple Chat" removes participant avatars and
            message reactions.
          </p>
          <div className="create-channel__form-toggle-wrapper">
            On
            <Toggle
              className="create-channel__form-toggle"
              isOn={createChannelForm.simple_chat}
              onClick={() =>
                setCreateChannelForm((form) => ({
                  ...form,
                  simple_chat: !form.simple_chat,
                }))
              }
            />
            Off
          </div>
        </FormItem>

        {/* Add Welcome/CoC Message */}
        <FormItem label="Welcome Message">
          <p>
            Any text entered here will display as a "Welcome Message"
            in the chat box upon joining the Stream. A participant
            must acknowledge this message by clicking "OK" in order to
            participate in the chat feature
          </p>
          {standardInputs.welcome_message}
        </FormItem>

        {/** Title, subtitle, and description. */}
        {standardInputs.title}
        {standardInputs.subtitle}
        {standardInputs.description}

        {/** AWS Ivs Channel Selection */}
        <FormItem label="AWS IVS Channel">
          <IvsSelect
            ivs_playback_url={createChannelForm.ivs_playback_url}
            updateCreateChannelFormByKey={
              updateCreateChannelFormByKey
            }
          />
        </FormItem>

        {/** Poll question generation */}
        <FormItem label="Poll Questions">
          <PollQuestions
            isEditMode={isEditMode}
            channelId={createChannelForm.id}
            pollQuestions={pollQuestions || []}
            setPollQuestions={setPollQuestions}
          />
        </FormItem>

        <FormSubmission
          formErrorMessage={formErrorMessage}
          isEditMode={isEditMode}
          isTestChannel={isTestChannel}
          submitChannel={
            !isEditMode ? submitChannel : submitUpdateChannel
          }
          deleteChannel={submitDeleteChannel}
        />
      </form>
    </ThemeWrapper>
  );
};
