import { CustomEmoji as CustomEmojiType } from 'emoji-mart';
import Emoji from '../../../../assets/emoji';

export const customEmojis: (CustomEmojiType & { native?: string, customCategory?: string })[] = [
    {
        id: 'bumpy',
        colons: 'bumpy',
        name: 'bumpy',
        keywords: ['bumpy'],
        short_names: ['bumpy'],
        imageUrl: Emoji.bumpy,
        native: null,
        customCategory: 'Textures'
    },
    {
        id: 'curved',
        colons: 'curved',
        name: 'curved',
        keywords: ['curved'],
        short_names: ['curved'],
        imageUrl: Emoji.curved,
        native: null,
        customCategory: 'Lines'
    },
    {
        id: 'diagonal',
        colons: 'diagonal',
        name: 'diagonal',
        keywords: ['diagonal'],
        short_names: ['diagonal'],
        imageUrl: Emoji.diagonal,
        native: null,
        customCategory: 'Lines'
    },
    {
        id: 'horizontal',
        colons: 'horizontal',
        name: 'horizontal',
        keywords: ['horizontal'],
        short_names: ['horizontal'],
        imageUrl: Emoji.horizontal,
        native: null,
        customCategory: 'Lines'
    },
    {
        id: 'smooth',
        colons: 'smooth',
        name: 'smooth',
        keywords: ['smooth'],
        short_names: ['smooth'],
        imageUrl: Emoji.smooth,
        native: null,
        customCategory: 'Textures'
    },
    {
        id: 'straight',
        colons: 'straight',
        name: 'straight',
        keywords: ['straight'],
        short_names: ['straight'],
        imageUrl: Emoji.straight,
        native: null,
        customCategory: 'Lines'
    },
    {
        id: 'vertical',
        colons: 'vertical',
        name: 'vertical',
        keywords: ['vertical'],
        short_names: ['vertical'],
        imageUrl: Emoji.vertical,
        native: null,
        customCategory: 'Lines'
    },
    {
        id: 'wavy',
        colons: 'wavy',
        name: 'wavy',
        keywords: ['wavy'],
        short_names: ['wavy'],
        imageUrl: Emoji.wavy,
        native: null,
        customCategory: 'Lines'
    },
    {
        id: 'zig-zag',
        colons: 'zig-zag',
        name: 'zig-zag',
        keywords: ['zig-zag'],
        short_names: ['zig-zag'],
        imageUrl: Emoji.zigZag,
        native: null,
        customCategory: 'Lines'
    },
    // NEW
    {
        id: 'dot',
        colons: 'dot',
        name: 'dot',
        keywords: ['dot'],
        short_names: ['dot'],
        imageUrl: Emoji.dot,
        native: null,
        customCategory: 'Brushstrokes'
    },
    {
        id: 'fine-line',
        colons: 'fine-line',
        name: 'fine-line',
        keywords: ['fine-line'],
        short_names: ['fine-line'],
        imageUrl: Emoji.fineLine,
        native: null,
        customCategory: 'Brushstrokes'
    },
    {
        id: 'translucent',
        colons: 'translucent',
        name: 'translucent',
        keywords: ['translucent'],
        short_names: ['translucent'],
        imageUrl: Emoji.translucent,
        native: null,
        customCategory: 'Brushstrokes'
    },
    {
        id: 'active',
        colons: 'active',
        name: 'active',
        keywords: ['active'],
        short_names: ['active'],
        imageUrl: Emoji.active,
        native: null,
        customCategory: 'Brushstrokes'
    },
    {
        id: 'thin',
        colons: 'thin',
        name: 'thin',
        keywords: ['thin'],
        short_names: ['thin'],
        imageUrl: Emoji.thin,
        native: null,
        customCategory: 'Brushstrokes'
    },
    {
        id: 'opaque',
        colons: 'opaque',
        name: 'opaque',
        keywords: ['opaque'],
        short_names: ['opaque'],
        imageUrl: Emoji.opaque,
        native: null,
        customCategory: 'Brushstrokes'
    },
    {
        id: 'rough',
        colons: 'rough',
        name: 'rough',
        keywords: ['rough'],
        short_names: ['rough'],
        imageUrl: Emoji.rough,
        native: null,
        customCategory: 'Textures'
    },
    {
        id: 'impasto',
        colons: 'impasto',
        name: 'impasto',
        keywords: ['impasto'],
        short_names: ['impasto'],
        imageUrl: Emoji.impasto,
        native: null,
        customCategory: 'Textures'
    },
    {
        id: 'thick',
        colons: 'thick',
        name: 'thick',
        keywords: ['thick'],
        short_names: ['thick'],
        imageUrl: Emoji.thick,
        native: null,
        customCategory: 'Textures'
    },
    
    {
        id: 'foreground',
        colons: 'foreground',
        name: 'foreground',
        keywords: ['foreground'],
        short_names: ['foreground'],
        imageUrl: Emoji.foreground,
        native: null,
        customCategory: 'Space'
    },
    {
        id: 'midground',
        colons: 'midground',
        name: 'midground',
        keywords: ['midground'],
        short_names: ['midground'],
        imageUrl: Emoji.midground,
        native: null,
        customCategory: 'Space'
    },
    {
        id: 'background',
        colons: 'background',
        name: 'background',
        keywords: ['background'],
        short_names: ['background'],
        imageUrl: Emoji.background,
        native: null,
        customCategory: 'Space'
    },

    {
        id: 'realistic-landscape',
        colons: 'realistic-landscape',
        name: 'realistic-landscape',
        keywords: ['realistic-landscape'],
        short_names: ['realistic-landscape'],
        imageUrl: Emoji.realisticLandscape,
        native: null,
        customCategory: 'Proportion'
    },
    {
        id: 'abstract-landscape',
        colons: 'abstract-landscape',
        name: 'abstract-landscape',
        keywords: ['abstract-landscape'],
        short_names: ['abstract-landscape'],
        imageUrl: Emoji.abstractLandscape,
        native: null,
        customCategory: 'Proportion'
    },
    {
        id: 'realistic-portrait',
        colons: 'realistic-portrait',
        name: 'realistic-portrait',
        keywords: ['realistic-portrait'],
        short_names: ['realistic-portrait'],
        imageUrl: Emoji.realisticPortrait,
        native: null,
        customCategory: 'Proportion'
    },
    {
        id: 'abstract-portrait',
        colons: 'abstract-portrait',
        name: 'abstract-portrait',
        keywords: ['abstract-portrait'],
        short_names: ['abstract-portrait'],
        imageUrl: Emoji.abstractPortrait,
        native: null,
        customCategory: 'Proportion'
    },
    {
        id: 'asymmetrical',
        colons: 'asymmetrical',
        name: 'asymmetrical',
        keywords: ['asymmetrical'],
        short_names: ['asymmetrical'],
        imageUrl: Emoji.asymmetrical,
        native: null,
        customCategory: 'Balance'
    },
    {
        id: 'symmetrical',
        colons: 'symmetrical',
        name: 'symmetrical',
        keywords: ['symmetrical'],
        short_names: ['symmetrical'],
        imageUrl: Emoji.symmetrical,
        native: null,
        customCategory: 'Balance'
    },
    {
        id: 'balance',
        colons: 'balance',
        name: 'balance',
        keywords: ['balance'],
        short_names: ['balance'],
        imageUrl: Emoji.balance,
        native: null,
        customCategory: 'Balance'
    },


    
];
