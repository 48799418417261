import React, {
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { ChatContext } from '@agallagher777/stream-chat-react';
import { MessageListItem } from './messageListItem';
import { useStreamChannel } from '../../shared/hooks/useStreamChannel';
import { AdminContext } from '../../../contexts/adminContext';
import { UserContext } from '../../../contexts/userContext';

const MESSAGE_LIST_LENGTH = 30;

export const MessageList: React.FC<{
  simpleChat: boolean;
  scrollLock?: boolean;
}> = ({ simpleChat, scrollLock }) => {
  // Channel information
  const { messages, channelId, channel } = useStreamChannel();
  const messagesRef = useRef(messages);

  // User information
  const { isAdmin } = useContext(AdminContext);
  const { userId } = useContext(UserContext);

  // If we are inspecting messages in lock mode.
  const [isLocked, setIsLocked] = useState(false);

  // Decide which list to render depending on lock state.
  const list = isLocked ? messagesRef.current : messages;

  // Filter out any messages that should not be shown.
  const filteredList = list.filter(
    (message) => message.type !== 'deleted' && !message.silent,
  );

  // Set messagesRef whenever scrollLock changes
  useEffect(() => {
    if (isAdmin && scrollLock !== isLocked) {
      messagesRef.current = messages;
      setIsLocked(scrollLock);
    }
  }, [scrollLock, isAdmin, messages, isLocked, setIsLocked]);

  // For message reactions
  // if !simple_chat
  const reactToMessage = useCallback(
    async (
      messageId: string,
      type: string,
      hasReactionFromUser: boolean,
    ) => {
      try {
        if (!hasReactionFromUser) {
          await channel.sendReaction(messageId, { type }, userId);
        } else {
          await channel.deleteReaction(messageId, type, userId);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [channel, userId],
  );

  return (
    <div className="chat__message-list">
      {[...filteredList]
        .slice(
          Math.max(filteredList.length - MESSAGE_LIST_LENGTH, 0),
          filteredList.length,
        )
        .reverse()
        .map((message) => (
          <MessageListItem
            key={message.id}
            message={message}
            isAdmin={isAdmin}
            channelId={channelId}
            userId={userId}
            reactToMessage={reactToMessage}
            simpleChat={simpleChat}
          />
        ))}
    </div>
  );
};
