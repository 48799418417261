import { useEffect, useState } from "react";

// Hook to get current time
// adapted from https://javascript.plainenglish.io/usetime-react-hook-f57979338de
export const useTime = (refreshCycle = 3600000) => {
    // Returns the current time
    // and queues re-renders every `refreshCycle` milliseconds (default: 3600000 ms/1 hr)

    const [now, setNow] = useState(getTime());

    useEffect(() => {
        // Regularly set time in state
        // (this will cause your component to re-render when time updates)
        const intervalId = setInterval(() => setNow(getTime()), refreshCycle);

        // Cleanup interval
        return () => clearInterval(intervalId);

        // Specify dependencies for useEffect
    }, [refreshCycle, setNow]);

    return now;
};

export const getTime = () => {
    const now = new Date();
    now.setSeconds(0, 0);
    return now;
};
