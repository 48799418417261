import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Wrap async funciton in hook that manages state.
 * @param {() => Promise<any>} fn - function to wrap.
 * @returns {[() => Promise<void>, boolean]} wrapped function, loading state.
 */
export const useLoadingRequest = (fn: () => Promise<any>): [() => Promise<void>, boolean] => {
    // For pushing history on successful submission.
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false); // Form loading

    const req = useCallback(async () => {
        try {
            setIsLoading(true);
            await fn();
            
            // Navigate back to admin after successful submission.
            history.push('/admin');
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, [history, fn]);

    return [req, isLoading];
}
