import React, { useEffect, useState } from 'react';
import { ThemeWrapper } from '../../shared/themeWrapper';
import { GuestUserProfile } from './util/guestUsers';
import { CloseIcon } from '../../../assets/icons/index';

const ANIMATION_TIME = 400;

const hasShownUserInfo = sessionStorage.getItem('hasShownUserInfo');

type UserInfoProps = {
  className: string;
  userName: GuestUserProfile;
};
export const UserInfo: React.FC<UserInfoProps> = ({
  className,
  userName,
}) => {
  // # TODO => Break this into a reducer.
  const [isActive, setIsActive] = useState(!hasShownUserInfo);

  useEffect(() => {
    let sto: ReturnType<typeof setTimeout>;

    if (!isActive) {
      sessionStorage.setItem('hasShownUserInfo', 'true');

      setTimeout(() => {
        setIsActive(false);
      }, ANIMATION_TIME);
    }

    return () => {
      if (sto) {
        clearTimeout(sto);
      }
    };
  }, [isActive]);

  let userInfoClassName = `${className} user-info`;
  if (!isActive) {
    userInfoClassName = `${userInfoClassName} user-info--hidden user-info`;
  }

  return (
    <ThemeWrapper elementType="article" className={userInfoClassName}>
      <button
        className="user-info__close"
        onClick={() => setIsActive(false)}
      >
        <CloseIcon />
      </button>
      <div className="user-info__image-wrapper">
        <img
          className="user-info__image"
          src={userName.icon}
          alt={userName.userName}
        />
      </div>
      <div className="user-info__description">
        <h4>{userName.userName}</h4>

        <p>
          You're chatting as {userName.userName}!{'  '}
          <a
            className="a-brand-link user-info__link"
            href={userName.collectionUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more about this artwork in our collection.
          </a>
        </p>
      </div>
    </ThemeWrapper>
  );
};
