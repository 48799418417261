import { executePayloadRequest } from './httpService';
import { Channel, ChannelInput } from '../../shared/channelPayloads';
import { download } from '../util/download';

/**
 * Create a new channel.
 * @param {ChannelInput} - ChannelInput object that describes channel.
 * @returns {Channel} - create channel.
 * @throws - @see httpService
 */
export const createChannel: (channelInput: ChannelInput) => Promise<Channel> = async (channelInput) => (
	executePayloadRequest<Channel>({
		url: '/api/channel',
		method: 'POST',
		data: channelInput
	})
);

/**
 * Update an existing channel.
 * @param {ChannelInput} - ChannelInput object that describes channel.
 * @returns {Channel} - updated channel.
 * @throws - @see httpService
 */
export const updateChannel: (channelInput: ChannelInput) => Promise<Channel> = async (channelInput) => (
	executePayloadRequest<Channel>({
		url: '/api/channel',
		method: 'PUT',
		data: channelInput
	})
);

/**
 * Delete an existing channel by slug or id.
 * @param {string | number} channelIdentifier - channel id or slug.
 * @returns {{ channel: Channel }} - ChannelInput object that describes channel.
 * @throws - @see httpService
 */
export const deleteChannel: (channelIdentifier: string | number) => Promise<{
	deleted: boolean,
	channel: Channel,
}> = async (channelIdentifier) => (
	executePayloadRequest<{
		deleted: boolean,
		channel: Channel,
	}>({
		url: `/api/channel/${channelIdentifier}`,
		method: 'DELETE',
		data: {}
	})
);

/**
 * Get a channel from server.
 * @param {string} id - Channel Id for channel to retrieve
 * @returns {Channel} - ChannelInput object that describes channel.
 * @throws - @see httpService
 */
export const getChannel: (id: string) => Promise<Channel> = async (id) => (
	executePayloadRequest<Channel>({
		url: `/api/channel/${id}`,
		method: 'GET',
	})
);

/**
 * Get all channels from server.
 * @returns {Channel[]} - array of ChannelInput object that describes channel.
 * @throws - @see httpService
 */
export const getChannels: () => Promise<Channel[]> = async () => (
	executePayloadRequest<Channel[]>({
		url: '/api/channel',
		method: 'GET',
	})
);

/**
 * Get all channels from server.
 * @returns {Channel[]} - array of ChannelInput object that describes channel.
 * @throws - @see httpService
 */
export const downloadLogs: (id: string) => Promise<void> = async (id) => {
	const res = await executePayloadRequest<{ messageLog: string }>({
		url: `/api/channel/${id}/logs`,
		method: 'GET',
	});

	download(`${id}__logs.csv`, res.messageLog);
};

/**
 * Get all channels from server.
 * @returns {Channel[]} - array of ChannelInput object that describes channel.
 * @throws - @see httpService
 */
export const banUsers: (id: string, userIds: string[]) => Promise<any> = async (
	id,
	userIds,
) => (
	executePayloadRequest<any>({
		url: `/api/channel/${id}/ban`,
		method: 'POST',
		data: { userIds },
	})
);

/**
 * Get all participants from a single channel.
 * @returns {string} - list of channel participants
 * @throws - @see httpService
 */
export const downloadParticipants: (id: string) => Promise<void> = async (
	id
) => {
	const res = await executePayloadRequest<{ participants: any }>({
		url: `/api/channel/${id}/participants`,
		method: "GET",
	});

	download(`${id}__participants.txt`, res.participants);
};

/**
 * End chat by setting disabled
 */
export const endChat: (id: string) => Promise<void> = async (id) => (
	executePayloadRequest<void>({
		url: `/api/channel/${id}/end`,
		method: "POST"
	})
)

/**
 * Restart chat by setting disabled
 */
export const restartChat: (id: string) => Promise<void> = async (id) => (
	executePayloadRequest<void>({
		url: `/api/channel/${id}/restart`,
		method: "POST"
	})
)