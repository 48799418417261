import config from '../../config';
import icon1 from './AppleVendor.png';
import icon2 from './BowGuard.png';
import icon3 from './Cup of Chocolate.png';
import icon4 from './FélixHippolyte-Lucas.png';
import icon5 from './MarieMurer.png';
import icon6 from './PaintedCanister.png';
import icon7 from './PhilosophicalThought.png';
import icon8 from './PorttraitOfAMan.png';
import icon9 from './SaintLawrence.png';
import icon10 from './ThePastAndThePresent.png';
import icon11 from './unpleasantSurprise--1.png';
import icon12 from './faceMask.png';
import icon13 from './cardPlayers.png';
import icon14 from './cardPlayers--2.png';
import icon15 from './cardPlayers--3.png';
import icon16 from './movement.png';
import icon17 from './aSkull.png';
import icon18 from './aRabbit.png';
import icon19 from './pears.png';
import icon20 from './aWoman.png';
import icon21 from './weatherVane.png';
import icon22 from './cuffBracelet.png';
import icon23 from './flowers.png';
import icon24 from './parrot.png';
import icon25 from './bird--2.png';
import icon26 from './pullEscutcheon.png';
import icon27 from './pewterFlagon.png';
import icon28 from './chainLink.png';
import icon29 from './pinkRose.png';
import icon30 from './ironHalberd.png';
import icon31 from './bronzeHorse.png';
import icon32 from './flowerPiece.png';
import icon33 from './birdFacingLeft.png';
import icon34 from './woodenParrot.png';
import icon35 from './doorHinge.png';
import icon36 from './coveredJar.png';
import icon37 from './chaimSoutine.png';
import icon38 from './georgesBraque.png';
import icon39 from './windsorArmchair.png';
// import icon40 from './andiron.jpg';  Removed because image is problematic
import icon41 from './charlesDemuth.jpg';
import icon42 from './fluidLamp.jpg';
import icon43 from './foodBowl.jpg';
import icon44 from './candleStick.jpg';
import icon45 from './sideChair.jpg';
import icon46 from './ironFork.jpg';
import icon47 from './ironHook.jpg';
import icon48 from './stand.jpg';
import icon49 from './keyholeEscutcheon.jpg';
import icon50 from './ceremonialAxe.jpg';
import icon51 from './oilLamp.jpg';
import icon52 from './ring.jpg';
import icon53 from './stool.jpg';
import icon54 from './doorPull.jpg';
import icon55 from './tinCoffeepot.jpg';
import icon56 from './jeanRenoir.jpg';
import icon57 from './waterJar.jpg';
import icon58 from './vanGoyen.jpg';
import icon59 from './doorKnocker.jpg';
import icon60 from './storageJar.jpg';
import icon61 from './signForALocksmith.jpg';

const localLocations = {
  icon1: icon1,
  icon2: icon2,
  icon3: icon3,
  icon4: icon4,
  icon5: icon5,
  icon6: icon6,
  icon7: icon7,
  icon8: icon8,
  icon9: icon9,
  icon10: icon10,
  icon11: icon11,
  icon12: icon12,
  icon13: icon13,
  icon14: icon14,
  icon15: icon15,
  icon16: icon16,
  icon17: icon17,
  icon18: icon18,
  icon19: icon19,
  icon20: icon20,
  icon21: icon21,
  icon22: icon22,
  icon23: icon23,
  icon24: icon24,
  icon25: icon25,
  icon26: icon26,
  icon27: icon27,
  icon28: icon28,
  icon29: icon29,
  icon30: icon30,
  icon31: icon31,
  icon32: icon32,
  icon33: icon33,
  icon34: icon34,
  icon35: icon35,
  icon36: icon36,
  icon37: icon37,
  icon38: icon38,
  icon39: icon39,
  // icon40: icon40,
  icon41: icon41,
  icon42: icon42,
  icon43: icon43,
  icon44: icon44,
  icon45: icon45,
  icon46: icon46,
  icon47: icon47,
  icon48: icon48,
  icon49: icon49,
  icon50: icon50,
  icon51: icon51,
  icon52: icon52,
  icon53: icon53,
  icon54: icon54,
  icon55: icon55,
  icon56: icon56,
  icon57: icon57,
  icon58: icon58,
  icon59: icon59,
  icon60: icon60,
  icon61: icon61,
};

const s3Locations = {
  icon1:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/AppleVendor.png',
  icon2:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/BowGuard.png',
  icon3:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/Cup+of+Chocolate.png',
  icon4:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/FélixHippolyte-Lucas.png',
  icon5:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/MarieMurer.png',
  icon6:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/PaintedCanister.png',
  icon7:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/PhilosophicalThought.png',
  icon8:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/PorttraitOfAMan.png',
  icon9:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/SaintLawrence.png',
  icon10:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/ThePastAndThePresent.png',
  icon11:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/unpleasantSurprise--1.png',
  icon12:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/faceMask.png',
  icon13:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/cardPlayers.png',
  icon14:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/cardPlayers--2.png',
  icon15:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/cardPlayers--3.png',
  icon16:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/movement.png',
  icon17:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/aSkull.png',
  icon18:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/aRabbit.png',
  icon19:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/pears.png',
  icon20:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/aWoman.png',
  icon21:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/weatherVane.png',
  icon22:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/cuffBracelet.png',
  icon23:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/flowers.png',
  icon24:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/parrot.png',
  icon25:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/bird--2.png',
  icon26:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/pullEscutcheon.png',
  icon27:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/pewterFlagon.png',
  icon28:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/chainLink.png',
  icon29:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/pinkRose.png',
  icon30:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/ironHalberd.png',
  icon31:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/bronzeHorse.png',
  icon32:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/flowerPiece.png',
  icon33:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/birdFacingLeft.png',
  icon34:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/woodenParrot.png',
  icon35:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/doorHinge.png',
  icon36:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/coveredJar.png',
  icon37:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/chaimSoutine.png',
  icon38:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/georgesBraque.png',
  icon39:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/windsorArmchair.png',
  // icon40: 'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/andiron.jpg',
  icon41:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/charlesDemuth.jpg',
  icon42:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/fluidLamp.jpg',
  icon43:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/foodBowl.jpg',
  icon44:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/candleStick.jpg',
  icon45:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/sideChair.jpg',
  icon46:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/ironFork.jpg',
  icon47:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/ironHook.jpg',
  icon48:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/stand.jpg',
  icon49:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/keyholeEscutcheon.jpg',
  icon50:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/ceremonialAxe.jpg',
  icon51:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/oilLamp.jpg',
  icon52:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/ring.jpg',
  icon53:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/stool.jpg',
  icon54:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/doorPull.jpg',
  icon55:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/tinCoffeepot.jpg',
  icon56:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/jeanRenoir.jpg',
  icon57:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/waterJar.jpg',
  icon58:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/vanGoyen.jpg',
  icon59:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/doorKnocker.jpg',
  icon60:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/storageJar.jpg',
  icon61:
    'https://barnes-live.s3.amazonaws.com/prod/assets/images/userIcons/signForALocksmith.jpg',
};

// Dependending on if this is dev or prod, export local or s3 src, respectively.
export default config.nodeEnv === 'development'
  ? localLocations
  : s3Locations;
