import React, { useContext } from 'react';
import {
	Route,
	Switch,
	Redirect,
    useRouteMatch,
} from 'react-router-dom';
import { ThemeWrapper } from '../shared/themeWrapper';
import { AuthContext } from '../../contexts/authContext';
import { Login } from './login';
import { Console } from './console/console';
import { ChannelManager } from './channelManager/channelManager';

export const Admin = () => {
    // For authentication in admin panel
    const { isLoggedIn } = useContext(AuthContext);

    // For obtaining current path.
    const match = useRouteMatch();

    return (
        <ThemeWrapper
            className="admin"
            elementType="main"
        >
            {/** Admin content */}
            <div className="admin__content">
                <Switch>
                    
                    {/** Admin console */}
                    {
                        isLoggedIn && (
                            <Route exact path={match.path}>
                                <Console />
                            </Route>
                    )}

                    {/** Create channel */}
                    {
                        isLoggedIn && (
                            <Route path={`${match.path}/create-channel`}>
                                <ChannelManager />
                            </Route>
                        )
                    }
                    
                    {/** Admin login */}
                    <Route path={`${match.path}/login`}>
                        <Login />
                    </Route>

                    {/** Catch any non-existing routes and redirect to root. */}
                    <Route>
                        <Redirect to={`${match.path}/login`} />
                    </Route>
                </Switch>
            </div>
        </ThemeWrapper>
    );
};
