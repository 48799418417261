import React from 'react';
import { ThemeWrapper } from './themeWrapper';

type StatusModalProps = {
    isActive?: boolean;
    header: string;
    className?: string;
};
export const StatusModal: React.FC<StatusModalProps> = ({
    isActive = true,
    header,
    children,
    className,
}) => {
    let overlayClassName = 'modal-overlay';
    if (isActive) {
        overlayClassName = `${overlayClassName} modal-overlay--active`;
    }

    let modalClassName = 'modal';
    if (className) {
        modalClassName = `${modalClassName} ${className} modal`;
    }

    return (
        <div className={overlayClassName}>
            <ThemeWrapper
                elementType="div"
                className={modalClassName}
            >
                <h2 className="modal__header">
                    {header}
                </h2>
                {children}
            </ThemeWrapper>
        </div>
    );
};
