import {
    useState,
    useEffect,
    useMemo
} from 'react';
import { Message } from 'stream-chat';
import { useStreamChannel } from '../../../shared/hooks/useStreamChannel';
import { getPoll } from '../../../../services/pollService';
import { Poll } from '../../../../../shared/channelPollPayloads';

/**
 * Determine active poll from message thread.
 * @param {Message[]} messages messages from channel.
 * @returns {{ activePoll: string | void }} current active poll.
 */
export const calculateActivePoll = (messages: Message[]): { activePoll: string | void } => (
    messages.reduce(({ activePoll }, { silent, text }) => {
        // If there is a silent message
        if (silent) {
            // If the text matches the current question
            return {
                activePoll: activePoll && activePoll === text
                    ? undefined
                    : text,
            }
        // If the message is not silent, return
        } else {
            return { activePoll };
        }            
    }, { activePoll: undefined, })
);

/**
 * Get active poll information.
 */
export const useActivePoll = (): Poll | void => {
    const [poll, setPoll] = useState<Poll | void>(undefined);
    const { messages } = useStreamChannel();

    // Find active poll from messages
    const { activePoll } = useMemo(() => calculateActivePoll(messages), [messages]);

    // If there is an active poll, fetch it.
    useEffect(() => {
        const fetchPoll = async () => {
            if (activePoll) {
                const poll = await getPoll(parseInt(activePoll));
                setPoll(poll);
            } else {
                setPoll(undefined);
            }
        };

        fetchPoll();
    }, [activePoll]);

    return poll;
};
