import React, {
	useEffect,
	useContext,
	useCallback,
	useState,
	useRef,
} from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../contexts/authContext';
import { Splash } from '../shared/splash';
import { signIn } from '../../services/authenticationService';
import { useSignOut } from './hooks/useSignOut';

// Get google auth api. from global window object.
const gapi = (window as any).gapi as any;

export const Login: React.FC = () => {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
	const history = useHistory();
	const [hasFailedLogin, setHasFailedLogin] = useState(false);
	const [googleSignInRendered, setGoogleSignInRendered] = useState(false);
	const signOut = useSignOut();

	/**
	 * Function that is called when a user successfully signs in with Google
	 * Note: This function is also called when a user is already authenticated via Google,
	 * but reloads the page or opens a new tab
	 */
	const onSuccess = useCallback(async (googleUser) => {
		// At this point, user is technically signed in with Google 
		// But we must validate they belong to our orgs - so extract id token
		const { id_token } = googleUser.getAuthResponse();

		try {
			// Send it to our server for verification it belongs to our domains
			const { authenticated } = await signIn(id_token);

			if (authenticated) {
				setIsLoggedIn(true);
				history.push('/admin');
			} else {
				// If user is not within our org, it is an unallowed login -- update the state and sign them out.
				setHasFailedLogin(true);
			}

		} catch (e) {
			// On sign in error, set state back to default and log error.
			console.error(e);
		}
	}, [history, setIsLoggedIn]);

	// failure handler.
	const onFailure = useCallback(() => console.log('Failed to sign in with Google'), []);

	/** Renders the Google sign in button */
	const renderSignInButton = useCallback(() => {
		// Render the sign in button
		gapi.load('signin2', () => {
			gapi.signin2.render('my-signin2', {
				'scope': 'profile email',
				'width': 240,
				'height': 50,
				'longtitle': true,
				'theme': 'dark',
				'onsuccess': onSuccess,
				'onfailure': onFailure
			});
		});

		setGoogleSignInRendered(true);
	}, [onSuccess, onFailure]);

	const signOutOnClick = useCallback(async () => {
		setIsLoggedIn(false);
		signOut();
	}, [signOut, setIsLoggedIn])

	/** For operations when the component mounts */
	useEffect(() => {
		const initialize = async () => {
			// Load the auth2 functions
			gapi.load('auth2', () => {
				gapi.auth2.init({
					client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
				});
			});
		};

		initialize();
	}, [isLoggedIn, history]);

	return (
        <Splash
            subtitle="Please sign in with Google using your Barnes email to get started."
        >
			{/** Google button is mounted here. */}
			{
				<div className="log-in__button-wrapper">
					<div id="my-signin2" />
				</div>
			}

			{
				!googleSignInRendered && 
					<button
						className="btn-barnes"
						onClick={renderSignInButton}
					>
						Log In
					</button>
			}
			{
				isLoggedIn && 
					<button
						className="btn-barnes"
						onClick={signOutOnClick}
					>
						Log Out
					</button>
			}

			<LoginError hasFailedLogin={hasFailedLogin}/>
        </Splash>
	)
}

type LoginErrorProps = { hasFailedLogin: boolean };
const LoginError: React.FC<LoginErrorProps> = ({
	hasFailedLogin
}) => {
	const { isAuthError } = useContext(AuthContext);

	// Calculate error height.
	const errorRef: React.MutableRefObject<HTMLDivElement> = useRef(null);
	const [errorDisplayHeight, setErrorDisplayHeight] = useState(null as number);

	// Calculate error display height.
	useEffect(() => {
		if (errorRef.current) {
			const { offsetHeight } = errorRef.current;
			setErrorDisplayHeight(offsetHeight);
		}
	}, [errorRef]);

	return (
		<div
			className="log-in__error"
			ref={errorRef}
			style={{ height: errorDisplayHeight || 'auto' }}
		>
			{/* Only display this span on first render or if following a bad login. */}
			<span
				style={{ display: (!errorDisplayHeight || hasFailedLogin) ? 'inline' : 'none' }}
			>
				Barnes Live is meant for use with a <u><strong>.barnesfoundation.org</strong></u>
				{" "}or <u><strong>.barnesclasses.org</strong></u> email account.
				{" "}Please sign in with an appropriate email.
			</span>

			{/* Only display this span on . */}
			{isAuthError && <span>Auth Error</span>}
		</div>
	)
}