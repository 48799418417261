import {
    createContext,
    useState,
    useCallback
} from 'react';

/**
 * Custom hook to manage dark theme.
 * @returns {[boolean, (boolean) => void]} array similar to return from useState.
 */
export const useDarkTheme = (): [boolean, (isDarkTheme: boolean) => void] => {
	// Set default value to logic dedicated to detecting color scheme preference.
	const [isDarkTheme, setIsDarkTheme] = useState(prefersDarkMode);

	// Update local storage while we update our state variable.
	const updateTheme = useCallback((isDarkTheme: boolean) => {
		localStorage.setItem('isDarkTheme', isDarkTheme ? 'true' : 'false');
		setIsDarkTheme(isDarkTheme);
	}, []);

	return [isDarkTheme, updateTheme];
};

/**
 * Check if user prefers dark mode, or their last selection in localStorage.
 * @see https://web.dev/prefers-color-scheme/
 */
export const prefersDarkMode = (() => {
    // Check to see if browser supports prefers-color-scheme.
    let isDarkMode = false;

    try {
        // Check if browser supports media queries.
        const isDarkModeSupported = window.matchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all';

        // If darkmode is supported and media query is allowed, check if user prefers dark.
        if (isDarkModeSupported && !localStorage.getItem('isDarkTheme')) {
            const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            isDarkMode = Boolean(darkModeMediaQuery.matches); // Redundant, but prevents NPE.
            localStorage.setItem('isDarkTheme', 'true'); // Update localStorage preferences.
        }

    } catch (e) {
        // On exception, default to light mode.
        isDarkMode = false;
    } finally {
        return Boolean(
            // Check localStorage
            (
                localStorage.getItem('isDarkTheme') &&
                localStorage.getItem('isDarkTheme') === 'true'
            ) ||
            // Or there is nothing in global storage
            (
                !localStorage.getItem('isDarkTheme') &&
                isDarkMode
            )
        )
    }
})();

export type DarkThemeContextType = {
    isDarkTheme: boolean;
    setIsDarkTheme: (isDarkTheme: boolean) => void;
};

export const DarkThemeContext = createContext<DarkThemeContextType>({
    isDarkTheme: true,
    setIsDarkTheme: (isDarkTheme: boolean) => null,
});
