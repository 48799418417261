import React, {
	useEffect,
	useRef,
	useState
} from 'react';
import {
	PlayerError,
	PlayerEventType,
	registerIVSTech,
	VideoJSIVSTech
} from 'amazon-ivs-player';
import videojs, { VideoJsPlayer } from 'video.js';
import { ThemeWrapper } from '../../shared/themeWrapper';
import { usePoster } from './hooks/usePoster';
import { BarnesLogo } from '../../../assets/icons';
import 'video.js/dist/video-js.css';

// Link to offline poster.
const barnesOfflinePosterSrc = 'https://barnes-live.s3.amazonaws.com/prod/assets/images/images/barnesOfflinePoster.jpg';

/**
 * 
 * @param mountElement 
 * @param playbackUrl 
 * @param callbacks
 */
const setUpPlayer: (
	mountElement: HTMLElement,
	playbackUrl: string,
	callbacks: {
		[key in 'setIsPlayerPlaying' | 'setIsPlayerMuted' | 'setIsLoaded']: (arg: boolean) => void;
	}
) => VideoJsPlayer & VideoJSIVSTech = (
	mountElement,
	playbackUrl,
	{
		setIsPlayerPlaying,
		setIsPlayerMuted,
		setIsLoaded
	}
) => (
	videojs(
		mountElement,
		{
			autoplay: true,
			controls: true,
			techOrder: ['AmazonIVS'],
			muted: true,
			defaultVolume: 1,
			// poster: barnesOfflinePosterSrc,
		},
		function onPlayerReady() {
			const player = (this as VideoJsPlayer & VideoJSIVSTech);

			if (player) {
				// Add src
				player.src(playbackUrl);
				
				// Set up HTML5 video event listeners
				player.on('play', () => setIsPlayerPlaying(!player.paused()));
				player.on('pause', () => setIsPlayerPlaying(!player.paused()));
				player.on('volumechange', () => setIsPlayerMuted(player.muted()));

				// Set up IVS listeners
				const { PlayerState } = player.getIVSEvents();
				player.getIVSPlayer().addEventListener(PlayerState.READY, () => setIsLoaded(true));
				player.getIVSPlayer().addEventListener(PlayerState.ENDED, () => {
					// This will bring up poster again.
					player.hasStarted(false);

					setIsLoaded(false);
				});
			}
		}
	) as VideoJsPlayer & VideoJSIVSTech
);

// Configure the Amazon IVS package
registerIVSTech(videojs, {
  wasmWorker:
    "https://barnes-live.s3.amazonaws.com/prod/updated-4_13_21-amazon-ivs-wasmworker.min.js",
  wasmBinary:
    "https://barnes-live.s3.amazonaws.com/prod/updated-4_13_21-amazon-ivs-wasmworker.min.wasm",
});

type VideoStreamModuleProps = { playbackUrl: string };
/** Component that provides video stream functionality from the Amazon IVS service */
export const VideoStreamModule: React.FC<VideoStreamModuleProps> = ({
	playbackUrl
}) => {
	const videojsRef = useRef(null);
	const [videojsPlayer, setVideojsPlayer] = useState<VideoJsPlayer & VideoJSIVSTech>(undefined);
	const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
	const [isPlayerMuted, setIsPlayerMuted] = useState(true); // Safe to assume that player will defalt to muted.
	const [isPlayerPlaying, setIsPlayerPlaying] = useState(false);
	const isShowingPoster = usePoster();

	// Set up video js player
	useEffect(() => {
		let player: VideoJsPlayer & VideoJSIVSTech;
		let playerErrorHandler: (ivsStatus: PlayerError) => void;
		let playerEvent: typeof PlayerEventType;
		let sto: ReturnType<typeof setTimeout>;

		// Player event listener callbacks
		const playCallback = (isPlaying) => setIsPlayerPlaying(isPlaying);
		const muteCallback = (isMuted) => setIsPlayerMuted(isMuted);
		const loadedCallback = (isLoaded) => setIsPlayerLoaded(isLoaded);

		// Instantiate Video.js
		if (playbackUrl && !player) {
			player = setUpPlayer(
				videojsRef.current.children[0],
				playbackUrl,
				{

					setIsPlayerPlaying: playCallback,
					setIsPlayerMuted: muteCallback,
					setIsLoaded: loadedCallback,
				}
			);

			// Recursive call on AWS IVS 404
			// Resets player
			playerErrorHandler = () => {
				sto = setTimeout(() => {
					player = setUpPlayer(
						videojsRef.current.children[0],
						playbackUrl,
						{
		
							setIsPlayerPlaying: playCallback,
							setIsPlayerMuted: muteCallback,
							setIsLoaded: loadedCallback,
						}
					);

					playerEvent = player.getIVSEvents().PlayerEventType;
					player.getIVSPlayer().addEventListener(playerEvent.ERROR, playerErrorHandler);
					setVideojsPlayer(player);
				}, 10000);
			};

			playerEvent = player.getIVSEvents().PlayerEventType;
			player.getIVSPlayer().addEventListener(playerEvent.ERROR, playerErrorHandler);
			setVideojsPlayer(player);
		}
		
		return () => {
			// Cleanup, remove player event listeners and dispose of player.
			if (player) {
				if (playerEvent && playerErrorHandler) {
					player.getIVSPlayer().removeEventListener(playerEvent.ERROR, playerErrorHandler);
				}
				
				player.dispose();				
			}

			if (sto) {
				clearTimeout(sto);
			}
		};
	}, [playbackUrl]);

	// Hide button on unmute
	let buttonWrapperClassName = 'video__unmute-button-wrapper';
	if (!isPlayerMuted || !isPlayerPlaying) {
		buttonWrapperClassName = `${buttonWrapperClassName} video__unmute-button-wrapper--hidden`;
	}

	// Hide overlay on play.
	let overlayClassName = 'video__opacity-overlay';
	if (!isPlayerPlaying && isPlayerLoaded) {
		overlayClassName = `${overlayClassName} video__opacity-overlay--active`;
	}

	// Show poster after X seconds
	let posterClassName = 'video__poster';
	if (!isPlayerLoaded && isShowingPoster) {
		posterClassName = `${posterClassName} video__poster--active`;
	}

	return (
		<ThemeWrapper
			className="video-wrapper"
			elementType="div"
		>
			{/** Poster Overlay */}
			<img
				alt=""
				className={posterClassName}
				src={barnesOfflinePosterSrc}
			/>

			{/** Unmute button. */}
			<div className={buttonWrapperClassName}>
				<button
					className="btn-barnes video__unmute-button"
					onClick={() => videojsPlayer.muted(false)}
				>
					<span role="img" aria-label="Speaker with three sound waves">🔊</span>{" "}
					Press To Unmute
				</button>
			</div>

			{/** Opacity overlay */}
			<button
				className={overlayClassName}
				onClick={() => {
					// On overlay press, play and unmute.
					if (videojsPlayer) {
						videojsPlayer.play();
						videojsPlayer.muted(false);	
					}
				}}
			>
				<BarnesLogo className="video__opacity-overlay-logo" />
				<span className="video__opacity-overlay-message">
					▶ Press To Play
				</span>
			</button>

			{/** Video.js Player */}
			<div
				className="video"
				ref={videojsRef}
				data-vjs-player
			>
				<video
					id="video-js"
					className="video-js"
				/>
			</div>
		</ThemeWrapper>
	);
};
