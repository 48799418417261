import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { StatusModal } from '../../../shared/statusModal';
import { UserModalProps } from './userModalProps';
import {
  GuestUserProfile,
  guestUserAvatars,
} from '../../chat/util/guestUsers';
import { useFilter } from '../../chat/hooks/useFilter';

const randomOrderedAvatars = guestUserAvatars.sort(
  () => Math.random() - 0.5,
);

type AvatarButtonProps = {
  icon: string;
  userName: string;
  collectionUrl: string;
  i: number;
  setAvatar: any;
  selected: boolean;
};
const AvatarButton: React.FC<AvatarButtonProps> = ({
  icon,
  userName,
  collectionUrl,
  i,
  setAvatar,
  selected,
}: AvatarButtonProps) => {
  const onClick = (e) => {
    e.preventDefault();
    setAvatar({ icon, userName, collectionUrl });
  };

  return (
    <button
      key={`${userName}${i}`}
      className={classnames('stream__user-avatar-button', {
        'stream__user-avatar-button--selected': selected,
      })}
      onClick={onClick}
    >
      <div>
        <img src={icon} alt={userName} />
      </div>
      <span>{userName}</span>
    </button>
  );
};

export const AvatarUserModal: React.FC<UserModalProps> = ({
  submit,
}) => {
  const filter = useFilter();
  const [isError, setIsError] = useState(false);
  const [profanityError, setProfanityError] = useState({
    isError: false,
    field: '',
  });
  const [avatar, setAvatar] = useState<
    Omit<GuestUserProfile, 'name' | 'organization'>
  >({
    icon: '',
    userName: '',
    collectionUrl: '',
  });
  const [name, setName] = useState('');
  const [organization, setOrganization] = useState('');

  // Our submission, the total of the three inputs on modal.
  const userName: GuestUserProfile = useMemo(
    () => ({
      icon: avatar.icon,
      userName: avatar.userName,
      collectionUrl: avatar.collectionUrl,
      name,
      organization,
    }),
    [avatar, name, organization],
  );

  const isComplete = useMemo(
    () => Object.values(userName).every(Boolean),
    [userName],
  );

  const validate = (): boolean => {
    let valid = true;

    if (filter.isProfane(name)) {
      valid = false;
      setProfanityError({ isError: true, field: 'User name' });
    }

    if (filter.isProfane(organization)) {
      valid = false;
      setProfanityError({ isError: true, field: 'Organization' });
    }

    return valid;
  };

  const onClick = (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid && isComplete) {
      submit(userName);
    } else {
      setIsError(true);
    }
  };

  return (
    <StatusModal
      className="modal--max-width"
      header="Please select your Art Adventure Avatar:"
    >
      <form className="stream__user-input-form">
        {/** Legal name input */}
        <input
          className="stream__input"
          placeholder="Your First Name"
          type="text"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />

        {/** Organization input */}
        <input
          className="stream__input"
          placeholder="School or Organization"
          type="text"
          value={organization}
          onChange={({ target: { value } }) => setOrganization(value)}
        />

        {/** Avatar selection */}
        <div className="stream__user-avatar-selector">
          {randomOrderedAvatars.map(
            ({ icon, userName, collectionUrl }, i) => (
              <AvatarButton
                icon={icon}
                userName={userName}
                collectionUrl={collectionUrl}
                i={i}
                setAvatar={setAvatar}
                selected={icon === avatar.icon}
              />
            ),
          )}
        </div>

        <button className="btn-barnes" onClick={onClick}>
          Submit
        </button>
        {isError && !profanityError.isError && (
          <span className="stream__user-input-error">
            Please enter your name and school or organization.
          </span>
        )}
        {profanityError.isError && (
          <span className="stream__user-input-error">
            {profanityError.field} is not allowed.
          </span>
        )}
      </form>
    </StatusModal>
  );
};
