import { useCallback, useContext } from "react";
import * as uuid from "uuid";
import { AdminContext } from "../../../../contexts/adminContext";
import { chatClient } from "../util/chatClient";
import { GuestUserProfile } from "../util/guestUsers";
import Config from "../../../../config";
import barnesLogoSrc from "../../../../assets/images/barnesLogo.jpg";
import {
  createToken,
  createAdminUser,
} from '../../../../services/userService';

type SetUpUser = (
  isLoggedIn: boolean,
  userName: GuestUserProfile | string | void,
  email: string | void,
  channelId: string | void
) => Promise<boolean | void> | void;

/**
 * Convenience method for setting up a user.
 * @param {boolean} isLoggedIn - is this user an admin.
 * @param {string} userName - userName to set to.
 * @param {string} email - email to set to.
 * @param {string} channelId - channel that the user is associated with.
 * @returns {Promise<boolean>} - if this is an admin user.
 * @throws {error} network error on fetch or setting chatClient.
 */
export const setUpUser: SetUpUser = async (isLoggedIn, userName, email, channelId) => {
  try {
    // Get userId from storage
    let userId = sessionStorage.getItem("userId");

    // If user id does not exist, create one
    if (!userId) {
      userId = uuid.v4();
      sessionStorage.setItem("userId", userId);
    }

    // Get token from storage
    let token = sessionStorage.getItem("token");
    // Get channel id from storage
    const existingChannelId = sessionStorage.getItem("channelId");

    // Create a token if:
    //  - token does not exist
    //  - the channelId does not match the stored channelId (user is attending a new event)
    if (!token || channelId !== existingChannelId) {
      try {
        const resp = await createToken(userId);
        token = resp.token;
        sessionStorage.setItem("token", token);
      } catch (err) {
        console.error(err);
      }
    }

    // Create guest user
    if (typeof userName === "string") {
      await chatClient.setUser(
        {
          id: userId,
          name: userName,
          email: email,
          channelId: channelId,
        },
        token
      );

      return false;

      // Create guest user with avatar
    } else if (userName && typeof userName !== "string") {
      await chatClient.setUser(
        {
          id: userId,
          name: userName.organization
            ? `${userName.name} — ${userName.organization}`
            : userName.name,
          image: userName.icon,
          avatarName: userName.userName,
          channelId: channelId,
        },
        token
      );

      return false;

      // If the user is logged in, create Admin user
    } else if (isLoggedIn) {
      const userId = Config.TEMP_streamApiUser;
      await createAdminUser(userId);
      await chatClient.setUser(
        {
          id: userId,
          name: 'Admin',
          image: barnesLogoSrc,
          channelId: channelId,
        },
        Config.TEMP_streamApiToken,
      );
      return true;

      // Else, create fallback guest user
    } else {
      await chatClient.setUser(
        {
          id: userId,
          name: "Guest",
          channelId: channelId,
          email: email,
        },
        token
      );

      return false;
    }
  } catch (e) {
    throw e;
  }
};

/**
 * Hook to set up a user.
 */
export const useSetUpUser = () => {
  // Hook to update global admin context.
  const { setIsAdmin } = useContext(AdminContext);

  // Return callback, basically this sets up a user for the chat client
  // and updates the global AdminContext out of the component.
  return useCallback<SetUpUser>(
    async (...args) => {
      try {
        const isAdmin = (await setUpUser(...args)) as boolean;
        setIsAdmin(isAdmin);
      } catch (e) {
        throw e;
      }
    },
    [setIsAdmin]
  );
};
