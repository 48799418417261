import React from 'react';

export type FormItemProps = { label: string; };
/**
 * Utility component for form items.
 */
export const FormItem: React.FC<FormItemProps> = ({
    label,
    children
}) => (
    <label className="create-channel__form-block">
        <span className="create-channel__form-label-text">{label}</span>
        {children}
    </label>
);
