import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from '../../../services/authenticationService';

/**
 * Custom hook to handle signing out.
 */
export const useSignOut = () => {
    const history = useHistory();

    return useCallback(async () => {
        await signOut();
        history.push('/');
    }, [history]);
};
