import {
    useRef,
    useState,
    useEffect
} from 'react';

// Default throttling timeout.
const TIMEOUT_INTERVAL = 1500;

/**
 * Throttle a frequently changing value.
 * @param value - value to be throttled
 * @param timeoutInterval throttling interval, defaults to @see TIMEOUT_INTERVAL.
 */
export const useThrottle: <T, >(throttledValue: T, timeoutInterval?: number) => T = (value, timeoutInterval = TIMEOUT_INTERVAL) => {
    const lastRan = useRef(Date.now()); // For calculating whether or not to update STO.
    const [throttledValue, setThrottledValue] = useState(value);
    const valueRef = useRef(value);

    useEffect(() => {
        let sto: ReturnType<typeof setTimeout>;
        valueRef.current = value;

        // Execute timeout based on last execution time, updating on value update.
        sto = setTimeout(() => {
            if (Date.now() - lastRan.current >= timeoutInterval) {
                setThrottledValue(valueRef.current);
                lastRan.current = Date.now();
            }
        }, Math.max(
            timeoutInterval - (Date.now() - lastRan.current),
            0
        ));

        return () => {
            clearTimeout(sto);
        }
    }, [value, timeoutInterval]);

    // Return value that only updates according to interval delay.
    return throttledValue;
};
