import React, { useCallback } from 'react';
import { ThemeWrapper } from './themeWrapper';
import {
    PollCreationInput,
    PollOption,
    Poll
} from '../../../shared/channelPollPayloads';

// Utility methods for generating form sections.
const generateBlankPollQuestion: (channel_id: number) => PollCreationInput = (
    channel_id
) => ({ title: "", channel_id, poll_options: [] });
const generateBlankPollQuestionOption = (): PollOption => ({ name: "" });

type PollSharedProps = {
    modPollQuestion?: Poll | void;
    isEditMode?: boolean;
    disabled?: boolean;
    activateQuestion?: (question: PollCreationInput) => void;
};

type PollQuestionsProps = {
    modPollQuestion?: Poll | void;
    isEditMode?: boolean;
    channelId?: number;
    pollQuestions: PollCreationInput[];
    setPollQuestions: React.Dispatch<React.SetStateAction<PollCreationInput[]>>;
} & PollSharedProps;
export const PollQuestions: React.FC<PollQuestionsProps> = ({
    modPollQuestion,
    isEditMode = false,
    channelId,
    pollQuestions,
    setPollQuestions,
    disabled = false,
    activateQuestion,
}) => {
    // Callback to create a new poll.
    const createNewPoll = useCallback(() => {
        setPollQuestions((pollQuestions) => ([
            ...pollQuestions,
            {
                ...generateBlankPollQuestion(channelId),
                poll_options: [...Array(2)].map(generateBlankPollQuestionOption)
            }
        ]));
    }, [channelId, setPollQuestions]);

    // Callback to delete a poll.
    const deletePoll = useCallback((index: number) => {
        setPollQuestions((pollQuestions) => (
            // Cut around specified index.
            [
                ...pollQuestions.slice(0, index),
                ...pollQuestions.slice(index + 1),
            ]
        ))
    }, [setPollQuestions]);

    return (
        <ThemeWrapper
            className="create-channel--ask create-channel"
            elementType="div"
        >
            {/** Map over all of our questions and render */}
            {
                pollQuestions.map((question, i) => (
                        <PollQuestion
                            key={i}
                            modPollQuestion={modPollQuestion}
                            isEditMode={isEditMode}
                            question={question}
                            questionIndex={i}
                            setQuestion={(question: PollCreationInput) => {
                                // Update pollQuestion array at specific index.
                                setPollQuestions([
                                    ...pollQuestions.slice(0, i),
                                    question,
                                    ...pollQuestions.slice(i + 1)
                                ])
                            }}
                            disabled={disabled}
                            activateQuestion={activateQuestion}
                            deletePoll={() => deletePoll(i)}
                        />
                    )
                )
            }

            {/** Control buttons for new poll questions */}
            {!disabled && (
                <button
                    className="btn-barnes create-channel__form-submit-button"
                    onClick={() => createNewPoll()}
                >
                    Add Question
                </button>
            )}
        </ThemeWrapper>
    );
};

type PollQuestionProps = {
    question: PollCreationInput;
    questionIndex: number;
    setQuestion: (question: PollCreationInput) => void;
    deletePoll: () => void;
} & PollSharedProps;
const PollQuestion: React.FC<PollQuestionProps> = ({
    modPollQuestion,
    isEditMode,
    question,
    questionIndex,
    setQuestion,
    disabled,
    activateQuestion,
    deletePoll
}) => {
    const isAskingThisQuestion = Boolean(modPollQuestion && modPollQuestion.title === question.title);

    // Style button to disabled if we are asking a question
    // but it is not this specific question.
    let buttonClassName = `create-channel__form-submit-button create-channel__form-submit-button--ask`;
    if (modPollQuestion && !isAskingThisQuestion) {
        buttonClassName = `${buttonClassName} create-channel__form-submit-button--disabled`;
    }

    return (
        <div className="create-channel__form-poll-questions">
            <div className="create-channel__form-option-wrapper">
                <input
                    className="create-channel__form-input create-channel__form-input--question"
                    placeholder="New Question"
                    type="text"
                    value={question.title}
                    onChange={({ target: { value }}) => {
                        setQuestion({ ...question, title: value })
                    }}
                    disabled={disabled}
                />
                {/** Control buttons for new poll questions */}
                {(!isEditMode && activateQuestion) && (
                    <button
                        className={buttonClassName}
                        onClick={(!modPollQuestion || modPollQuestion.title === question.title) ? () => activateQuestion(question) : undefined}
                    >
                        {
                            (modPollQuestion && modPollQuestion.title === question.title)
                                ? "Stop"
                                : "Ask"
                        }
                    </button>
                )}
            </div>

            {/** Map over all of our poll options and render */}
            {
                question.poll_options.map((option, i) => {
                    const isLastOption = i === question.poll_options.length - 1;
                    
                    let buttonClassName = "btn-barnes";
                    if (!isLastOption) {
                        buttonClassName = `${buttonClassName} create-channel__form-submit-button--delete`;
                    }
                    buttonClassName = `${buttonClassName} create-channel__form-plus`;

                    return (
                        <div
                            className="create-channel__form-option-wrapper"
                            key={`${questionIndex}${i}`}
                        >
                            <PollOptionComponent
                                option={option}
                                optionIndex={i + 1}
                                setOption={(option: PollOption) => {
                                    setQuestion({
                                        ...question,
                                        poll_options: [
                                            ...question.poll_options.slice(0, i),
                                            { ...question.poll_options[i], ...option,  },
                                            ...question.poll_options.slice(i + 1)
                                        ]
                                    })
                                }}
                                disabled={disabled}
                            />
                            {
                                !disabled && (
                                    <button
                                        className={buttonClassName}
                                        onClick={() => {
                                            // If this is our last option, we are going to generate a new option
                                            if (isLastOption) {
                                                setQuestion({
                                                    ...question,
                                                    poll_options: [
                                                        ...question.poll_options,
                                                        generateBlankPollQuestionOption()
                                                    ]
                                                });
                                            
                                            // Otherwise, we are going to delete this item
                                            } else {
                                                setQuestion({
                                                    ...question,
                                                    poll_options: [
                                                        ...question.poll_options.slice(0, i),
                                                        ...question.poll_options.slice(i + 1),
                                                    ]
                                                });
                                            }
                                            
                                        }}
                                    >
                                        {isLastOption ? "+" : "-"}
                                    </button>
                                )
                            }
                        </div>
                    );
                })
            }

            <button
                className="
                    btn-barnes
                    create-channel__form-submit-button
                    create-channel__form-submit-button--delete
                    create-channel__form-submit-button--margin-left
                    create-channel__form-submit-button--margin-top
                "
                onClick={(e) => {
                    // Stop event propogation.
                    e.preventDefault();

                    deletePoll();
                }}
            >
                Delete
            </button>
        </div>
    );
}


type PollOptionProps = {
    option: PollOption;
    optionIndex: number;
    setOption: (option: PollOption) => void;
    disabled: boolean;
};
const PollOptionComponent: React.FC<PollOptionProps> = ({
    option,
    optionIndex,
    setOption,
    disabled,
}) => (
    <label className="create-channel__form-block create-channel__form-block--row">
        <span className="create-channel__form-number">{optionIndex}.</span>
        <input
            className="create-channel__form-input create-channel__form-input--option"
            placeholder="Response Option"
            type="text"
            value={option.name}
            onChange={({ target: { value }}) => setOption({ name: value })}
            disabled={disabled}
        />
    </label>
);
