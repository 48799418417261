import React from 'react';
import { SpinnerSuspense } from '../../shared/spinner';
import { useLoadingRequest } from './hooks/useLoadingRequest';

type FormSubmissionProps = {
  formErrorMessage: string;
  isEditMode: boolean;
  isTestChannel: boolean;
  submitChannel: () => Promise<void>;
  deleteChannel: () => Promise<void>;
};
export const FormSubmission: React.FC<FormSubmissionProps> = ({
  formErrorMessage,
  isEditMode,
  isTestChannel,
  submitChannel,
  deleteChannel,
}) => {
  // Actual submit button.
  const [
    submitChannelLoadingWrapper,
    isSubmitLoading,
  ] = useLoadingRequest(submitChannel);
  const [deleteLoadingWrapper, isDeleteLoading] = useLoadingRequest(
    deleteChannel,
  );

  const isLoading = isSubmitLoading || isDeleteLoading;

  let buttonClassName =
    'btn-barnes create-channel__form-submit-button';
  if (formErrorMessage || isDeleteLoading) {
    buttonClassName = `${buttonClassName} btn-barnes--disabled`;
  }

  // Check to see if we have an error message.
  // If so, present it and block onClick.
  return (
    <>
      <div className="create-channel__form-submit-section">
        <button
          className={buttonClassName}
          onClick={
            !formErrorMessage && !isLoading
              ? submitChannelLoadingWrapper
              : undefined
          }
        >
          <SpinnerSuspense
            className="create-channel__spinner"
            isLoaded={!isSubmitLoading}
          >
            {!isEditMode ? 'Submit' : 'Save Changes'}
          </SpinnerSuspense>
        </button>
        {isEditMode && !isTestChannel && (
          <button
            className="btn-barnes create-channel__form-submit-button create-channel__form-submit-button--delete"
            onClick={!isLoading ? deleteLoadingWrapper : undefined}
          >
            <SpinnerSuspense
              className="create-channel__spinner"
              isLoaded={!isDeleteLoading}
            >
              Delete
            </SpinnerSuspense>
          </button>
        )}
      </div>
      <span className="create-channel__form-error">
        {formErrorMessage}
      </span>
    </>
  );
};
