import React, {
    useRef,
    CSSProperties
} from 'react';
import { useHistory } from 'react-router-dom';
import { formatSingleDate, formatTimeRange } from '../../../util/formatting';
import { Channel } from '../../../../shared/channelPayloads';
import { Spinner } from '../../shared/spinner';

type ChannelTableProps = { channelMapValue: Channel[] | void };
export const ChannelTable: React.FC<ChannelTableProps> = ({ channelMapValue }) => {
    // For pushing history
    // We do not use a href here as we do not want to break the table.
    const history = useHistory();

    // Use JS to determine with of table for centering
    // non cell items.
    const bodyRef = useRef<HTMLTableSectionElement>();
    const tableCenteredStyle: CSSProperties = bodyRef.current
        ? {
            opacity: 1,
            position: 'absolute',
            width: bodyRef.current.offsetWidth
        }
        : { opacity: 0 };

    const isLoaded = Boolean(channelMapValue);
    const hasChannels = channelMapValue && channelMapValue.length;

    return (
        <table className="console__table">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Slug</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Target Audience</th>
                    <th>Enabled</th>
                </tr>
            </thead>
            <tbody
                ref={bodyRef}
            >
            {
                (isLoaded && hasChannels)
                    ? (channelMapValue as Channel[]).map((channel) => (
                        <tr
                            key={channel.slug}
                            onClick={() => history.push("/admin/create-channel/?mode=EDIT", { channel })}
                        >
                            <td>{channel.title}</td>
                            <td className="a-brand-link">{channel.slug}</td>
                            <td>{formatSingleDate(channel.availability_start)}</td>
                            <td>{formatTimeRange(channel.availability_start, channel.availability_end)}</td>
                            <td>{channel.demographic}</td>
                            <td>{channel.enabled ? 'Yes' : 'No'}</td>
                        </tr>
                    ))
                    : (
                        <div className="console__table-spinner">
                            {/** # TODO => Fix this, nested ternary is not very legible. */}
                            {
                                (isLoaded && !hasChannels)
                                    ? <div style={tableCenteredStyle}>No channels</div>
                                    : <Spinner style={tableCenteredStyle} />
                            }
                        </div>
                    )
            }
            </tbody>
        </table>
    );
};
