import { useContext, useMemo } from 'react';
import { Message, Channel } from 'stream-chat';
import { ChannelContext } from '@agallagher777/stream-chat-react';
import { useThrottle } from './useThrottle';

export const useStreamChannel = () => {
    const channelContext = useContext(ChannelContext) as { messages: Message[], channel: Channel };
    const messages = useThrottle(channelContext.messages);
    const channelId: string | void = useMemo(() => (channelContext as any)?.channel?.data?.id, [channelContext]);
    const frozen: boolean = useMemo(() => (channelContext as any)?.channel?.data?.frozen, [channelContext])
    const disabled: boolean = useMemo(() => (channelContext as any)?.channel?.data?.disabled, [channelContext])

    return {
        messages,
        channelId,
        channel: channelContext.channel,
        frozen,
        disabled,
    };
};
