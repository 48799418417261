import React, { useState } from 'react';
import { StatusModal } from '../../../shared/statusModal';
import { UserModalProps } from './userModalProps';
import validator from 'validator';
import { useFilter } from '../../chat/hooks/useFilter';

export const NamedUserModal: React.FC<
  UserModalProps & { isMinor: boolean }
> = ({ submit, isMinor }) => {
  const filter = useFilter();
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [profanityError, setProfanityError] = useState({
    isError: false,
    field: '',
  });

  const getUserName = () => {
    if (!userName) {
      return null;
    }
    if (isMinor && !organization) {
      return null;
    }
    return organization ? `${userName} — ${organization}` : userName;
  };

  const validateProfanity = (): boolean => {
    let valid = true;

    if (filter.isProfane(userName)) {
      valid = false;
      setProfanityError({ isError: true, field: "User name"})
    }

    if (filter.isProfane(organization)) {
      valid = false;
      setProfanityError({ isError: true, field: "Organization"})
    }

    if (filter.isProfane(email)) {
      valid = false;
      setProfanityError({ isError: true, field: "Email" })
    }

    return valid;
  }

  const onClick = (e) => {
    e.preventDefault();
    const name = getUserName();
    const isNotProfane = validateProfanity();

    // Show error if invalid email is entered
    if (isNotProfane && name && email) {
      if (validator.isEmail(email)) {
        submit(name, email);
        if (!validator.isEmail(email)) {
          setIsError(true);
        } else {
          submit(name);
        }
      }
    }

    if (isNotProfane && name && !email) {
      submit(name);
    } else {
      setIsError(true);
    }
  }

  return (
    <StatusModal
      className="modal--max-width"
      header="Please enter your name:"
    >
      <form className="stream__user-input-form">
        <input
          className="stream__input"
          placeholder="Name"
          type="text"
          value={userName as string}
          onChange={({ target: { value } }) => setUserName(value)}
        />
        {!isMinor && (
          <input
            className="stream__input"
            placeholder="Email (optional)"
            type="text"
            value={email as string}
            onChange={({ target: { value } }) => setEmail(value)}
          />
        )}
        {isMinor && (
          <input
            className="stream__input"
            placeholder="School or Organization"
            type="text"
            value={organization as string}
            onChange={({ target: { value } }) =>
              setOrganization(value)
            }
          />
        )}
        <button
          className="btn-barnes"
          onClick={onClick}
        >
          Submit
        </button>
        {isError && !profanityError.isError && (
          <span
            className="stream__user-input-error"
            data-testid="error-message"
          >
            Please enter your name{' '}
            {isMinor
              ? 'and organization'
              : 'and a valid email (optional)'}
            .
          </span>
        )}
        {profanityError.isError && (
          <span className="stream__user-input-error">
            {profanityError.field} is not allowed.
          </span>
        )}
      </form>
    </StatusModal>
  );
};
