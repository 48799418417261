import { createContext } from 'react';

export type UserContextType = {
  userId: string;
  setUserId?: (string: string) => void;
  token: string;
  setToken?: (string: string) => void;
  manualUserName: string;
  setManualUserName?: (string: string) => void;
  channelId: string;
  setChannelId?: (string: string) => void;
  joinedChat: boolean;
  setJoinedChat?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserContext = createContext<UserContextType>({
  userId: localStorage.getItem("userId"),
  setUserId: (userId: string) => localStorage.setItem("userId", userId),
  token: localStorage.getItem("token"),
  setToken: (token: string) => localStorage.setItem("token", token),
  manualUserName: localStorage.getItem("manualUserName"),
  setManualUserName: (userName: string) => localStorage.setItem("manualUserName", userName),
  channelId: localStorage.getItem("channelId"),
  setChannelId: (channelId: string) => localStorage.setItem("channelId", channelId),
  joinedChat: false,
  setJoinedChat: (joinedChat: boolean) => null,  
});