import React, { useEffect } from 'react';
import { ChannelInputForm } from './channelManager';
import { useIvsChannels } from './hooks/useIvsChannels';

type IvsSelectProps = {
    ivs_playback_url: string | void;
    updateCreateChannelFormByKey: (spread: { [key in keyof ChannelInputForm]?: any }) => void;
}
export const IvsSelect: React.FC<IvsSelectProps> = ({
    ivs_playback_url,
    updateCreateChannelFormByKey,
}) => {
    // Array of AWS IVS channel names and urls
    // Update our selection to the first item when IVS urls are loaded.
    const ivsChannels = useIvsChannels();
    useEffect(() => {
        updateCreateChannelFormByKey({
            ivs_playback_url: ivs_playback_url || ivsChannels[0] && ivsChannels[0].playbackUrl
        });
    }, [ivsChannels, ivs_playback_url, updateCreateChannelFormByKey])

    return (
      <select
        className="create-channel__form-input create-channel__form-input--select"
        onChange={({ target: { value } }) =>
          updateCreateChannelFormByKey({
            ivs_playback_url: value,
          })
        }
        value={ivs_playback_url || undefined}
      >
        {ivsChannels.map((channel) => {
          const channelUrlSegments = channel.playbackUrl.split(".");
          const [last, second] = channelUrlSegments.reverse();

          return (
            <option key={channel.playbackUrl} value={channel.playbackUrl}>
              {Boolean(second && last) ? `${second}.${last} (${channel.name})` : `${channel.name ? channel.name : ""}`}
            </option>
          );
        })}
      </select>
    );
};
