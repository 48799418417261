import React, { useState } from "react";
import { gql, useSubscription } from "@apollo/client";
import classnames from "classnames";

import { Channel } from "../../../../shared/channelPayloads";

const SubscribeToTranscriptEvents = gql`
    subscription onCreateTranscriptEvent($channelId: String!) {
        onCreateTranscriptEvent(channelId: $channelId) {
            channelId timestamp transcript id
        }
    }
`

type ClosedCaptionsProps = {
    channel: Channel;
}

export const ClosedCaptions: React.FC<ClosedCaptionsProps> = ({
    channel,
}) => {
    const channelUrlSegments = channel.ivs_playback_url.split(".");
    const [_m3u8, channelId] = channelUrlSegments.reverse();
    const [showClosedCaptions, setShowClosedCaptions] = useState(false);
    const [captionsButtonText, setCaptionsButtonText] = useState("Closed Captions")

    const { loading, error, data } = useSubscription(
        SubscribeToTranscriptEvents,
        { variables: { channelId } }
    )

    // Handler for toggle closed captions on/off
    const handleClosedCaptions = async () => {
        const showCaptions = !showClosedCaptions
        setShowClosedCaptions(showCaptions)
        showCaptions
            ? setCaptionsButtonText("Turn off Closed Captions")
            : setCaptionsButtonText("Closed Captions")
    }

    return (
        <>
            {!loading && !error && data && (
                <div className={classnames("stream__captions-wrapper", { "stream__captions-wrapper--active": showClosedCaptions })}>
                    <button onClick={handleClosedCaptions} className="btn-barnes stream__captions-button">
                        {captionsButtonText}
                    </button>
                    {channel && showClosedCaptions && (
                        <p className="stream__captions-text">
                            {data.onCreateTranscriptEvent.transcript}
                        </p>
                    )}
                </div>
            )}
        </>
    )
}