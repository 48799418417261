import { executePayloadRequest } from "./httpService";

/**
 * Creates a token for a user
 * @returns {string} - token for connecting as a user
 * @throws - @see httpService
 */
export const createToken: (userId: string) => Promise<any> = async (userId) =>
  executePayloadRequest<any>({
    url: `/api/user`,
    method: "POST",
    data: { userId },
  });

export const createAdminUser: (
  userId: string,
) => Promise<any> = async (userId) => {
  executePayloadRequest<any>({
    url: `/api/user/admin`,
    method: 'POST',
    data: { userId },
  });
};