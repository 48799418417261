import React from 'react';
import { PollQuestions } from '../shared/pollQuestions';
import { PollCreationInput, Poll } from '../../../shared/channelPollPayloads';

type ModPollQuestionsProps = {
    modPollQuestion: Poll | void;
    pollQuestions: PollCreationInput[];
    setPollQuestions: React.Dispatch<React.SetStateAction<PollCreationInput[]>>;
    setModPollQuestion: React.Dispatch<React.SetStateAction<Poll>>;
};
export const ModPollQuestions: React.FC<ModPollQuestionsProps> = ({
    modPollQuestion,
    pollQuestions,
    setPollQuestions,
    setModPollQuestion
}) => (
    <div className="description description--desktop"> {/** Only show on desktop */}
        <div className="container">
            <div className="description__header-section description__header-section--negative-bottom">
                <div>
                    <h1 className="description__header">Polling Questions</h1>
                    <p className="description__detail">Activate polls for audience by pressing ask.</p>
                </div>
            </div>

            <PollQuestions
                modPollQuestion={modPollQuestion}
                pollQuestions={pollQuestions}
                setPollQuestions={setPollQuestions}
                disabled
                activateQuestion={(question) => setModPollQuestion((modPollQuestion) => (
                    // If we do not currently have a question, update
                    // Otherwise, set back to null.
                    !modPollQuestion
                        ? question as Poll
                        : null
                    ))}
            />
        </div>
    </div>
);
