import React from 'react';
import { StatusModal } from '../../../shared/statusModal';
import { BroadcastingState } from '../../../shared/util/convertToChannelMap';
import { formatTimeRange, formatSingleDate } from '../../../../util/formatting';
import { Channel } from '../../../../../shared/channelPayloads';
import { PAST } from "../../../../constants";

type NotOnAirModalProps = {
    isBroadcasting: boolean;
    broadcastTimeStatus: BroadcastingState;
    channel: Channel;
};
export const NotOnAirModal: React.FC<NotOnAirModalProps> = ({
  isBroadcasting,
  broadcastTimeStatus,
  channel,
}) => (
  <StatusModal
    isActive={isBroadcasting}
    header="This broadcast is not currently on air."
  >
    {/** Outline error briefly depending on if it is upcoming or past. */}
    <p className="stream__error-detail">
      {broadcastTimeStatus === PAST
        ? `This broadcast aired from ${formatTimeRange(
            channel.availability_start,
            channel.availability_end
          )} on ${formatSingleDate(channel.availability_start)}.`
        : `This broadcast will air from ${formatTimeRange(
            channel.availability_start,
            channel.availability_end
          )} on ${formatSingleDate(channel.availability_start)}.`}
    </p>
  </StatusModal>
);
