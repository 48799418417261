import {
    useContext,
    useCallback,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/authContext';
import {
  TEACHER_ROUTE_DELIMITER,
  NAMED_ROUTE_DELIMITER,
  NAMED,
  ADMIN,
  AVATAR,
  ADULT,
} from "../../../../constants";
import { Channel } from '../../../../../shared/channelPayloads';

/**
 * Determine if we are using random user name or asking for input.
 * @param {string} url - current URL to be calculated.
 * @param {Channel['demographic'] | void} demographic - age demographic of channel.
 * @returns {boolean} true if this is a /random/, otherwise false.
 */
const isNamedLogin = (url: string, demographic: Channel['demographic'] | void): boolean => {
    // Split up route params, remove any empty strings.
    const routeParams = url.split("/").filter(Boolean);

    return (
      // Check route
      (routeParams.length === 2 &&
        (routeParams[0] === TEACHER_ROUTE_DELIMITER ||
          routeParams[0] === NAMED_ROUTE_DELIMITER)) ||
      // Or see if channel demographic matches
      demographic === ADULT
    );
};

// Authentication level.
export type UserNameLevel = 'admin' | 'named' | 'avatar';

/**
 * Custom hook for obtaining user level.
 * @return {(isOverride: boolean, isRandomUserName: boolean) => UserNameLevel} current userNameLevel.
 */
export const useUserNameLevel = (demographic: Channel['demographic'] | void) => {
    // If an admin is logged in, we don't want to unnecessarily prompt them for their name.
    const { isLoggedIn } = useContext(AuthContext);

    // For checking if url dictates this should be random or not.
    const match = useRouteMatch();
    const isRandomUserName = !isNamedLogin(match.url, demographic);

    return useCallback((isOverride: boolean | void): UserNameLevel => {  
        // Return level of user name
        let askForUserNameLevel: UserNameLevel;
        if (isLoggedIn && isOverride !== null && !isOverride) {
            askForUserNameLevel = ADMIN;
        } else if (!isRandomUserName) {
            askForUserNameLevel = NAMED;
        } else {
            askForUserNameLevel = AVATAR;
        }

        return askForUserNameLevel;
    }, [isLoggedIn, isRandomUserName]);
};
