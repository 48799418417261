export default {
    nodeEnv: process.env.NODE_ENV!! as 'development' | 'test' | 'production',

    streamApiAppId: process.env.REACT_APP_STREAM_APP_ID!!,
    streamApiKey: process.env.REACT_APP_STREAM_API_KEY!!,
    streamApiSecret: process.env.REACT_APP_STREAM_API_SECRET!!,
    
    TEMP_streamApiUser: process.env.REACT_APP_STREAM_USER_ID!!,
	TEMP_streamApiToken: process.env.REACT_APP_STREAM_USER_TOKEN!!,
};
