// Current time on client invocation of app
export const CURRENT_DATE = new Date();

// Route delimiters
export const TEACHER_ROUTE_DELIMITER = 'teacher';
export const NAMED_ROUTE_DELIMITER = 'named';
export const POLL_ROUTE_DELIMITER = 'poll';

// Allow-list of emojis, mapped to Unicode key.
export const NOT_ALLOWED_EMOJIS = [
    '🕐', '🕑', '🕒', '🕓', '🕔', '🕕',
    '🕖', '🕗', '🕘', '🕙', '🕚', '🕛',
    '🕜', '🕝', '🕞', '🕟', '🕠', '🕡',
    '🕢', '🕣', '🕤', '🕥', '🕦', '🕧',
    '🍆', '🍑', '💊', '👮🏻‍♀️', '👮🏻‍♂️', '💩',
    '🚽', '🖕', '😡', '🤬', '👹', '👺',
    '👿','😈','🔫','💣','🔪',
].map(emoji => emoji.codePointAt(0).toString(16).toUpperCase());

// Allow-list of adult-only emojis, mapped to Unicode key.
export const ADULTS_ONLY_EMOJIS = [
    '🍷','🍾','🍺','🍻','🥃','🥂','🍸','🍶','🍹','🔞',
].map(emoji => emoji.codePointAt(0).toString(16).toUpperCase());

// meta tag content
export const META_TITLE = 'Barnes Live 📡';
export const META_IMAGE = 'https://s3.amazonaws.com/barnes-image-repository/images/5003_92ad85ffeb13d697_n.jpg';
export const META_DESCRIPTION = "The Barnes Foundation in Philadelphia is home to one of the world's greatest collections of impressionist, post-impressionist and early modern paintings.";
export const META_PLACENAME = 'Barnes Foundation';
export const CANONICAL_ROOT = 'https://live.barnesfoundation.org';

// Demographic types
export const MINOR = "MINOR";
export const ADULT = "ADULT";

// Username level
export const NAMED = "named";
export const ADMIN = "admin";
export const AVATAR = "avatar";

// Broadcast time status
export const PRESENT = "present"
export const PAST = "past"
export const FUTURE = "future"
export const TEST = "test"