import React, { useState, useCallback } from 'react';

import { banUsers } from '../../../services/channelService';

type MemberListItemProps = {
  member: {
    id: string;
    name: string;
    banned: boolean;
  };
  channelId: string | void;
};

export const MemberListItem: React.FC<MemberListItemProps> = ({
  member,
  channelId,
}) => {
  const [isBanned, setIsBanned] = useState(member.banned);

  // Ban a member
  const banUser = useCallback(async () => {
    try {
      if (channelId && member?.id) {
        await banUsers(channelId, [member.id]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsBanned(true);
    }
  }, [channelId, member]);

  return (
    <div className="chat__message">
      {isBanned ? (
        <span className="chat__muted">
          <i>Muted</i>
        </span>
      ) : (
        <button className="chat__delete" onClick={banUser}>
          Mute
        </button>
      )}
      <div className="chat__username">{member.name}</div>
    </div>
  );
};
