import { useState, useEffect } from 'react';
import { useAuthRequest } from '../../../../contexts/authContext';
import { listIvsChannels } from '../../../../services/ivsChannelService';

type IvsChannel = {
    playbackUrl: string;
    name: string
}

/**
 * Get list of IVS channels from server.
 * @returns {IvsChannel[]} - list of ivs channels.
 */
export const useIvsChannels = (): IvsChannel[] => {
    const [ivsChannels, setIvsChannels] = useState([]); // array of AWS IVS channels
    const listIvsChannelsAuth = useAuthRequest(listIvsChannels);

    // Get all AWS IVS channels.
    useEffect(() => {
        // Make call to server
        try {
            const fetchIvsChannels = async () => {
                const channels = await listIvsChannelsAuth();

                // arn is AWS equiv of id for our purposes
                // We will use these only as a string
                const ivsChannelIds = channels.map(({ playbackUrl, name }) => ({ playbackUrl, name }))

                // Check if we have ivs ids.
                if (ivsChannelIds.length) {
                    setIvsChannels(ivsChannelIds);
                } else {
                    throw new Error('Received empty array for ivs channels.');
                }
            };

            fetchIvsChannels();
        } catch (e) {
            console.log(e);
        }
    }, [listIvsChannelsAuth]);

    return ivsChannels;
};
