import React, {
    useEffect,
    useState
} from 'react';
import {
    Link,
    useRouteMatch,
} from 'react-router-dom';
import { ChannelTable } from './channelTable';
import { ThemeWrapper } from '../../shared/themeWrapper';
import { useAuthRequest } from '../../../contexts/authContext';
import { getChannels } from '../../../services/channelService';
import { Channel } from '../../../../shared/channelPayloads';
import { convertToChannelMap } from '../../shared/util/convertToChannelMap';
import { useSignOut } from '../hooks/useSignOut';

export type ChannelMap = {
    past: Channel[] | void,
    present: Channel[] | void,
    future: Channel[] | void,
    test: Channel[] | void,
};

/**
 * Admin panel section for viewing chats [current, future, past].
 */
export const Console = () => {
    const [channels, setChannels] = useState<ChannelMap>({
        past: undefined,
        present: undefined,
        future: undefined,
        test: undefined,
    });
    const signOut = useSignOut();

    // Wrap HTTP reqs in auth wrapper.
    const getChannelsAuth = useAuthRequest(getChannels);

    // For obtaining current path.
    const match = useRouteMatch();

    // Fetch channels.
    useEffect(() => {
        try {
            const fetchChannels = async () => {
                const channels = await getChannelsAuth();
                const channelMap = convertToChannelMap(channels);
                
                setChannels(channelMap);
            };

            fetchChannels();
        } catch (e) {
            console.log(e);
        }
    }, [getChannelsAuth]);

    return (
        <ThemeWrapper
            className="console"
            elementType="section"
        >
            {/** Header section */}
            <header className="console__header-section">
                <h1 className="console__header">Console</h1>
                <p className="console__detail">
                    Explore past, present, and future event channels.
                </p>

                {/** Link to create a new event */}
                <Link
                    className="btn-barnes console__button"
                    to={`${match.path}/create-channel`}
                >
                    Create New Event
                </Link>

                <button
                    className="btn-barnes console__button console__button--sign-out"
                    onClick={signOut}
                >
                    Log Out
                </button>
            </header>

            {/** Content */}
            <div className="console__content">
                {/** Current events, map over section and link to current events */}
                <h2 className="console__subheader">Available Channels</h2>
                <ChannelTable channelMapValue={channels.present}/>

                <h2 className="console__subheader">Future Channels</h2>
                <ChannelTable channelMapValue={channels.future}/>

                <h2 className="console__subheader">Past Channels</h2>
                <ChannelTable channelMapValue={channels.past}/>

                <h2 className="console__subheader">Test Channels</h2>
                <ChannelTable channelMapValue={channels.test}/>
            </div>
            
        </ThemeWrapper>
    );
};
